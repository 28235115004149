// import { Carousel } from '@material-tailwind/react';
import Home from "./Home"
import { BrowserRouter as Router, Route,Routes, Navigate, BrowserRouter  } from "react-router-dom";
import Viewproducts from "./components/seperatepages/viewproducts";
import ProductDetailsPage from "./components/productdetails/ProductDetailsPage";
import ProductCheckoutPage from "./components/productcheckout/ProductCheckoutpage";
import ProductFilter from "./components/productfliter/productfilterpage";
import AuthForm from "./components/Login/Login";
import AboutDetail from "./components/aboutdetail";
import Profile from "../src/components/profile";
import OrderPage from "../src/components/order";
import Accordion from "../src/components/finalcheckout/Accordion";
import Profile1 from "../src/components/profilepage/profile1";
import UserProfile from './pages/User/UserProfile';
import ForgotPassword from "./components/Login/forgot-password";




function App() {
  return (
  
     <BrowserRouter>
      <Routes >
        <Route exact path="/" element={<Home />} />
        <Route exact path="/Login" element={<AuthForm/>}/>
        <Route exact path="/ForgotPassword" element={<ForgotPassword/>} />
        <Route path="/user/:activepage" element={<UserProfile/>} />
        <Route exact path="/profile" element={<Profile/>}/>
        <Route exact path="/profile1" element={<Profile1/>}/>
        <Route exact path="/order" element={<OrderPage/>}/>
        <Route exact path="/aboutdetail"  element={<AboutDetail/>} />
        <Route exact path="/viewproducts/:sourceId" element={<Viewproducts />} />
        <Route exact path="/:Page/:sourceId/productdetailspage/:prd_id" element={<ProductDetailsPage />} />
        <Route exact path="/ProductCheckoutpage" element={<ProductCheckoutPage />} />
        <Route exact path="/ProductFinalCheckout" element={<Accordion />}/>
        <Route exact path="/Accordion" element={<Accordion />} />
        <Route exact path="/productfilterpage" element={<ProductFilter />} />
      </Routes >
    </BrowserRouter>
    
  );
}

export default App;
