// src/components/AuthForm.js
import React, { useState, useEffect } from "react";
import * as Components from "./Components";
import axios from "axios";
import Swal from "sweetalert2";
import gift_logo from "../../images/Navbar/Gift Genie Logo 2.svg";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
import { IoIosEye } from "react-icons/io";
import { IoIosEyeOff } from "react-icons/io";
import { useAuth } from "../AuthContext";
import { uuid } from "../uuid";
import { useLocation } from "react-router-dom";

function AuthForm() {
  const [signIn, toggle] = React.useState(true);
  const MySwal = withReactContent(Swal);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showsignIn, setShowSignIn] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const { isAuthenticated, LogIn, signOut } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  function isValidEmail(email) {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
      return true;
    } else {
      return false;
    }
  }

  const addUser = async (userData) => {
    try {
      const checkUserResponse = await axios.post(
        "https://clientgiftgeenie.design247.in/giftgeenieapi/postCheckUser.php",
        { userEmail: userData.userEmail }
      );

      if (checkUserResponse.status === 200) {
        // User already exists, show error message
        MySwal.fire({
          icon: "error",
          title: "User Already Exists",
          text: "The provided email is already associated with an existing account. Please try to log in.",
          confirmButtonColor: "#96887d",
          confirmButtonBorder: "#96887d",
        });
      } else {
      }
    } catch (error) {
      const signResponse = await axios.post(
        "https://clientgiftgeenie.design247.in/giftgeenieapi/insertLoginDeatils.php",
        userData
      );

      if (signResponse.status === 200) {
        // Handle success if needed

        // Show SweetAlert
        MySwal.fire({
          icon: "success",
          title: "Account Created Successfully!",
          text: "You can now log in with your credentials.",
          confirmButtonColor: "#96887d",
          confirmButtonBorder: "#96887d",
        }).then(() => {
          window.location.href = "/Login";
        });
      } else {
        console.error("Failed to add the user");
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const firstName = event.target.firstName.value;
    const lastName = event.target.lastName.value;
    const userEmail = event.target.userEmail.value;
    const userPass = event.target.userPass.value;
    const confirmPass = event.target.confirmPass.value;

    // Check if any field is blank
    if (!firstName || !lastName || !userEmail || !userPass || !confirmPass) {
      MySwal.fire({
        icon: "error",
        title: "Please fill all the required fields",
        text: "All fields are mandatory for sign up.",
        confirmButtonColor: "#96887d",
        confirmButtonBorder: "#96887d",
      });
      return;
    }

    if (isValidEmail(userEmail) === false) {
      MySwal.fire({
        icon: "error",
        title: "Invalid Email",
        text: "Please Check the Email Formate",
        confirmButtonColor: "#96887d",
        confirmButtonBorder: "#96887d",
      });
      return;
    }

    if (userPass !== confirmPass) {
      // Show error message if passwords don't match
      MySwal.fire({
        icon: "error",
        title: "Passwords do not match",
        text: "Please make sure the passwords match.",
        confirmButtonColor: "#96887d",
        confirmButtonBorder: "#96887d",
      });
      return;
    }

    const userId = uuid();
    // console.log(userId);

    const userData = {
      firstName: firstName,
      lastName: lastName,
      userEmail: userEmail,
      userPass: userPass,
      userID: userId,
    };

    await addUser(userData);
  };

  const handleSignIn = async (event) => {
    event.preventDefault();

    const userEmail = event.target.userEmail.value;
    const userPass = event.target.userPass.value;

    if (!userEmail || !userPass) {
      MySwal.fire({
        icon: "error",
        title: "Please fill all the required fields",
        text: "All fields are mandatory for sign In.",
        confirmButtonColor: "#96887d",
        confirmButtonBorder: "#96887d",
      });
      return;
    }

    const userData = {
      userEmail: userEmail,
      userPass: userPass,
    };

    // Save the current URL before navigating to the login page
    const currentUrl = location.pathname;

    try {
      const logResponse = await axios.post(
        "https://clientgiftgeenie.design247.in/giftgeenieapi/insertSignInDetails.php",
        userData
      );

      // console.log(logResponse);

      if (logResponse.status === 200) {
        // Handle success if neede
        setShowSignIn(true);
        localStorage.setItem("userEmail", logResponse.data.userName);
        localStorage.setItem("userId", logResponse.data.res);
        // Redirect the user back to the saved URL or a default page
        const redirectUrl = localStorage.getItem("redirectUrl") || "/";
        localStorage.removeItem("redirectUrl"); // Clear the saved URL
        navigate(redirectUrl, { state: { userEmail } });
      } else if (logResponse.status === 404) {
        MySwal.fire({
          icon: "error",
          title: "User not found",
          text: "If you are already registered, please check your email and password. If not, please create an account.",
          confirmButtonColor: "#96887d",
          confirmButtonBorder: "#96887d",
        });
      }
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "User not found",
        text: "If you are already registered, please check your email and password. If not, please create an account.",
        confirmButtonColor: "#96887d",
        confirmButtonBorder: "#96887d",
      });
      console.error("Error adding user:", error);
    }
  };

  return (
    <Components.Section className="bg-cover min-h-screen flex justify-center items-center">
      <div className="flex flex-col justify-center items-center mt-20 h-100vh mb-[50px]">
        <Components.Container className="bg-white rounded-lg shadow-md w-full max-w-md p-6 h-[500px]">
          <Components.SignUpContainer signinIn={signIn}>
            <Components.Form onSubmit={handleSubmit}>
              <Components.Title>Create Account</Components.Title>
              <Components.Span>*All Fields Are Mandatory</Components.Span>
              <Components.Input
                type="text"
                placeholder="First Name"
                name="firstName"
              />
              <Components.Input
                type="text"
                placeholder="Last Name"
                name="lastName"
              />
              <Components.Input
                type="email"
                placeholder="Email"
                name="userEmail"
              />
              <Components.Input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                name="userPass"
              />
              <span
                onClick={() => setShowPassword(!showPassword)}
                className="cursor-pointer absolute right-14 top-[61%]"
              >
                {showPassword ? (
                  <IoIosEyeOff className="h-5 w-5 text-black" />
                ) : (
                  <IoIosEye className="h-5 w-5 text-black" />
                )}
              </span>

              <Components.Input
                type={showPassword2 ? "text" : "password"}
                placeholder="Confirm Password"
                name="confirmPass"
              />

              <span
                onClick={() => setShowPassword2(!showPassword2)}
                className="cursor-pointer absolute right-14 top-[74%]"
              >
                {showPassword2 ? (
                  <IoIosEyeOff className="h-5 w-5 text-black" />
                ) : (
                  <IoIosEye className="h-5 w-5 text-black" />
                )}
              </span>

              <Components.Button type="submit">Sign Up</Components.Button>
            </Components.Form>
          </Components.SignUpContainer>

          <Components.SignInContainer signinIn={signIn}>
            <Components.Form onSubmit={handleSignIn}>
              <Components.Title>Sign in</Components.Title>
              <Components.Input
                type="email"
                placeholder="Email"
                name="userEmail"
              />
              <Components.Input
                type={showPassword1 ? "text" : "password"}
                placeholder="Password"
                name="userPass"
              />
              <span
                onClick={() => setShowPassword1(!showPassword1)}
                className="cursor-pointer absolute right-14 top-[49%]"
              >
                {showPassword1 ? (
                  <IoIosEyeOff className="h-5 w-5 text-black" />
                ) : (
                  <IoIosEye className="h-5 w-5 text-black" />
                )}
              </span>
              <span
                onClick={() => {
                  navigate("/ForgotPassword");
                }}
                className="cursor-pointer mb-3"
              >
                <Components.Anchor>Forgot your password?</Components.Anchor>
              </span>
              <Components.Button type="submit">Sigin In</Components.Button>
            </Components.Form>
          </Components.SignInContainer>

          <Components.OverlayContainer signinIn={signIn}>
            <Components.Overlay signinIn={signIn}>
              <Components.LeftOverlayPanel signinIn={signIn}>
                <Components.Title>
                  <div className="flex text-center">
                    <img src={gift_logo} alt="" className="w-16 mx-auto" />
                  </div>
                </Components.Title>
                <Components.Title>Welcome Back!</Components.Title>
                <Components.Paragraph>
                  To keep connected with us please login with your personal info
                </Components.Paragraph>
                <Components.GhostButton onClick={() => toggle(true)}>
                  Sign In
                </Components.GhostButton>
              </Components.LeftOverlayPanel>

              <Components.RightOverlayPanel signinIn={signIn}>
                <Components.Title>
                  <div className="flex text-center">
                    <img src={gift_logo} alt="" className="w-16 mx-auto" />
                  </div>
                </Components.Title>
                <Components.Title> Hello, Friend!</Components.Title>
                <Components.Paragraph>
                  Enter Your personal details and start journey with us
                </Components.Paragraph>
                <Components.GhostButton onClick={() => toggle(false)}>
                  Sigin Up
                </Components.GhostButton>
              </Components.RightOverlayPanel>
            </Components.Overlay>
          </Components.OverlayContainer>
        </Components.Container>
      </div>
    </Components.Section>
  );
}

export default AuthForm;
