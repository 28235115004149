import React, { useState, useEffect } from 'react'
import './UserAddress.css'
import Avatar from "../../images/Login/avatar.png";
import { FiEdit } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import { FaUserEdit } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { GiCancel } from "react-icons/gi";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";

const UserAddress = () => {
    const [show, setShow] = React.useState(false)
    const MySwal = withReactContent(Swal);
    const [isLoading, setIsLoading] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const [editMode1, setEditMode1] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [file, setFile] = useState(null);
    const navigate = useNavigate();
  
    const location = useLocation();
    const userEmail =
      location.state?.userEmail || localStorage.getItem("userEmail");
  
    const [user, setUser] = useState({
      avatar: Avatar,
    });
  
    const handleEditClick = () => {
      setEditMode(true);
    };
  
  
  
    const [formData, setFormData] = useState({
      user_address: "",
      company_name: "",
      company_address: "",
      user_pincode: "",
      city: "",
      state: "",
      country: "",
      region: 0,
      alternate_phone: "",
      address_type: "",
    });
  
  
    useEffect(() => {
      checkProfile();
    }, [userEmail]);
  
    const checkProfile = async () => {
      setIsLoading(true);
  
      try {
        const response = await axios.get(
          `https://clientgiftgeenie.design247.in/giftgeenieapi/getProfiledata.php?user_email=${userEmail}`
        );
  
        if (response.status === 200) {
          const responseData = response.data.res[0];
          const imageUrl = responseData.user_img;
  
          if (imageUrl) {
            setUser((prevUser) => ({
              ...prevUser,
              avatar: `https://clientgiftgeenie.design247.in/giftgeenieapi/${imageUrl}`,
            }));
          } else {
            console.error("user_img is undefined in responseData");
          }
          setFormData(responseData);
        } else {
          console.error("Failed to check authentication");
        }
      } catch (error) {
        console.error("Error checking authentication:", error);
      } finally {
        setIsLoading(false);
      }
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
  
      // Password validation
      if (name === "user_pass") {
        setPasswordVisible(false); // Hide password when editing
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
        if (!passwordRegex.test(value) && value !== "") {
          // Password should be at least 8 characters long and contain at least one letter and one number
          setFormSubmitted(false);
        }
      }
  
      // Phone validation
      if (name === "user_phone") {
        const phoneRegex = /^\d+$/;
        if (!phoneRegex.test(value) && value !== "") {
          // Phone should only contain numbers
          setPhoneError(true);
        } else {
          setPhoneError(false);
        }
      }
  
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      setFormSubmitted(true);
  
      axios
        .post("https://clientgiftgeenie.design247.in/giftgeenieapi/updateProfileDeatils.php", formData)
        .then((response) => {
          if (response.status === 200) {
            MySwal.fire({
              text: "Data Updated Successfully",
              icon: "success",
              confirmButtonColor: "#96887d",
              confirmButtonBorder: "#96887d",
            });
            checkProfile();
          }
          setEditMode1(false);
        })
        .catch((error) => {
          console.error("Error submitting form data:", error);
        });
    };
    const handleEditModeToggle = () => {
      setEditMode1(!editMode1); // Toggle the edit mode
    };

   
    return (
        <div className='useraddress'>
            

            
                <div className='addnew'>
                    <h1 className='mainhead1'>Add New Address</h1>
                    <form className="grid grid-cols-1 gap-4">
                    <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
              <div className='flex justify-end'>
                <button
                  type="button"
                  onClick={handleEditModeToggle}
                  className="text-sm font-semibold rounded-md text-white bg-[#96887d] px-3 py-2 border border-1 border-black-600 ml-2 mr-2"
                >
                  {editMode1 ? "Cancel" : <FaUserEdit />}
                </button>
                {editMode1 && (
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="rounded-md bg-[#C8AE7D] px-3 py-2 text-sm font-semibold text-white shadow-sm"
                  >
                    Update
                  </button>
                )}
              </div>
            </div>
         <div className="grid grid-cols-2 md:grid-cols-2 gap-4">
    
          <div className="relative mb-4 md:mb-0">
            <input
              type="text"
              id="floating_outlined"
              name="user_pincode"
              value={formData.user_pincode}
              disabled={!editMode1}
              onChange={(e) =>
                setFormData((prevData) => ({
                  ...prevData,
                  user_pincode: e.target.value,
                }))
              }
              class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
            />
            <label
              for="floating_outlined"
              class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >
              Pincode
            </label>
          </div>

          {/* <div className="relative mb-4 md:mb-0">
            <input
              type="text"
              id="floating_outlined"
              name="company_name"
              value={formData.company_name}
              disabled={!editMode1}
              onChange={(e) =>
                setFormData((prevData) => ({
                  ...prevData,
                  company_name: e.target.value,
                }))
              }
              class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
            />
            <label
              for="floating_outlined"
              class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >
              Company Name
            </label>
          </div>

          <div class="relative col-span-2">
            <textarea
              type="text"
              id="floating_outlined"
              name="company_address"
              value={formData.company_address}
              disabled={!editMode1}
              onChange={(e) =>
                setFormData((prevData) => ({
                  ...prevData,
                  company_address: e.target.value,
                }))
              }
              class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
            />
            <label
              for="floating_outlined"
              class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >
              Company Address
            </label>
          </div> */}


                    <div className="relative col-span-2">
            <textarea
              type="text"
              id="floating_outlined"
              name="user_address"
              value={formData.user_address}
              disabled={!editMode1}
              onChange={(e) =>
                setFormData((prevData) => ({
                  ...prevData,
                  user_address: e.target.value,
                }))
              }
              class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
            />
            <label
              for="floating_outlined"
              class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >
              Address
            </label>
          </div>

         

          
          <div className="relative mb-4 md:mb-0">
            <input
              type="text"
              id="floating_outlined"
              name="city"
              value={formData.city}
              disabled={!editMode1}
              onChange={(e) =>
                setFormData((prevData) => ({
                  ...prevData,
                  city: e.target.value,
                }))
              }
              class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
            />
            <label
              for="floating_outlined"
              class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >
              City
            </label>
          </div>

          <div class="relative">
            <input
              type="text"
              id="floating_outlined"
              name="state"
              value={formData.state}
              disabled={!editMode1}
              onChange={(e) =>
                setFormData((prevData) => ({
                  ...prevData,
                  state: e.target.value,
                }))
              }
              class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
            />
            <label
              for="floating_outlined"
              class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >
              State
            </label>
          </div>

          

          <div className="relative">
            <label class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
              Region:
            </label>
            <select
            name="region"
              value={formData.region}
              disabled={!editMode1}
              onChange={(e) =>
                setFormData((prevData) => ({
                  ...prevData,
                  region: e.target.value,
                }))
              }
              class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
            >
               <option value={0} disabled>
                      Select a region
                    </option>
                    <option value={1}>USA</option>
                    <option value={2}>UK</option>
                    <option value={3}>EUROPE</option>
            </select>
          </div>

          <div class="relative">
            <input
              type="text"
              id="floating_outlined"
              name="alternate_phone"
              disabled={!editMode1}
              maxLength={10}
              value={formData.alternate_phone}
              onChange={(e) =>
                setFormData((prevData) => ({
                  ...prevData,
                  alternate_phone: e.target.value,
                }))
              }
              class={`block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer
              ${
                phoneError ? "border-red-500" : ""
              }`}
            />
            {phoneError && (
                    <p className="text-red-500 text-sm mt-1">
                      Only numbers are allowed for phone.
                    </p>
                  )}
            <label
              for="floating_outlined"
              class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >
              Alternate Phone (Optional)
            </label>
          </div>
                        </div>

                        

                    </form>
                  
                   
                </div>
            
        </div>
    )
}

export default UserAddress