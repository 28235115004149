import React, { useState, useEffect } from "react";
import Avatar from "../../images/Login/avatar.png";
import Background from "../../images/Login/profilebackground.jpg";
import { FiEdit } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import { FaUserEdit } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { GiCancel } from "react-icons/gi";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";

import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";

export function Profile1() {
  const data = [
    {
      label: "HTML",
      value: "html",
      desc: `It really matters and then like it really doesn't matter.
      What matters is the people who are sparked by it. And the people
      who are like offended by it, it doesn't matter.`,
    },
    {
      label: "React",
      value: "react",
      desc: `Because it's about motivating the doers. Because I'm here
      to follow my dreams and inspire other people to follow their dreams, too.`,
    },
    {
      label: "Vue",
      value: "vue",
      desc: `We're not always in the position that we want to be at.
      We're constantly growing. We're constantly making mistakes. We're
      constantly trying to express ourselves and actualize our dreams.`,
    },
    {
      label: "Angular",
      value: "angular",
      desc: `Because it's about motivating the doers. Because I'm here
      to follow my dreams and inspire other people to follow their dreams, too.`,
    },
    {
      label: "Svelte",
      value: "svelte",
      desc: `We're not always in the position that we want to be at.
      We're constantly growing. We're constantly making mistakes. We're
      constantly trying to express ourselves and actualize our dreams.`,
    },
  ];

  const MySwal = withReactContent(Swal);
  const [isLoading, setIsLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [editMode1, setEditMode1] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [file, setFile] = useState(null);
  const navigate = useNavigate();

  const location = useLocation();
  const userEmail =
    location.state?.userEmail || localStorage.getItem("userEmail");

  const [user, setUser] = useState({
    name: "John Doe",
    email: "john@example.com",
    avatar: Avatar,
  });

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleRemoveClick = async (userEmail) => {
    // setUser({
    //   ...user,
    //   avatar: Avatar, // Set the avatar back to the default value or null if needed
    // });
    // setEditMode(false);

    try {
      const response = await axios.post(
        "https://clientgiftgeenie.design247.in/giftgeenieapi/removeProfileImage.php",
        userEmail
      );
      if (response.status === 200) {
        setUser({
          ...user,
          avatar: Avatar, // Set the avatar back to the default value
        });
        setEditMode(false);
      } else {
        console.error("Error removing profile image:", response.data.message);
      }
    } catch (error) {
      // Handle errors
      console.error("Error removing profile image:", error);
    }
  };

  const handleImageUpload = async (e) => {
    const selectedFile = e.target.files[0];

    const formData = new FormData();
    formData.append("user_img", selectedFile);
    formData.append("user_email", userEmail); // Add this line

    console.log(formData);

    try {
      const response = await axios.post(
        "https://clientgiftgeenie.design247.in/giftgeenieapi/updateProfileImage.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        const updatedUser = response.data;
        console.log("Updated User:", updatedUser);
        setUser((prevUser) => ({
          ...prevUser,
          avatar: `https://clientgiftgeenie.design247.in/giftgeenieapi/${updatedUser.avatar}`,
        }));
        localStorage.setItem("userAvatar", updatedUser.avatar);
        setEditMode(false);
      } else {
        console.error("Error uploading image:", response.data.message);
      }
    } catch (error) {
      // Handle errors
      console.error("Error uploading image:", error);
    }
  };

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    user_email: "",
    user_pass: "",
    user_phone: "",
    user_address: "",
    company_name: "",
    company_address: "",
    city: "",
    state: "",
    country: "",
    region: 0,
    user_img: "",
  });

  // const handleEditClick = () => {
  //   setEditMode(!editMode);
  // };

  // const handleRemoveClick = () => {
  //   // Handle image removal logic here
  //   console.log("Image removed");
  //   setEditMode(false); // Exit edit mode
  // };

  useEffect(() => {
    checkProfile();
  }, [userEmail]);

  const checkProfile = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(
        `https://clientgiftgeenie.design247.in/giftgeenieapi/getProfile.php?user_email=${userEmail}`
      );

      if (response.status === 200) {
        const responseData = response.data.res[0];
        console.log(responseData);
        const imageUrl = responseData.user_img;

        if (imageUrl) {
          setUser((prevUser) => ({
            ...prevUser,
            avatar: `https://clientgiftgeenie.design247.in/giftgeenieapi/${imageUrl}`,
          }));
        } else {
          console.error("user_img is undefined in responseData");
        }
        setFormData(responseData);
      } else {
        console.error("Failed to check authentication");
      }
    } catch (error) {
      console.error("Error checking authentication:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Password validation
    if (name === "user_pass") {
      setPasswordVisible(false); // Hide password when editing
      const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
      if (!passwordRegex.test(value) && value !== "") {
        // Password should be at least 8 characters long and contain at least one letter and one number
        setFormSubmitted(false);
      }
    }

    // Phone validation
    if (name === "user_phone") {
      const phoneRegex = /^\d+$/;
      if (!phoneRegex.test(value) && value !== "") {
        // Phone should only contain numbers
        setPhoneError(true);
      } else {
        setPhoneError(false);
      }
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    axios
      .post(
        "https://clientgiftgeenie.design247.in/giftgeenieapi/updateProfileDeatils.php",
        formData
      )
      .then((response) => {
        if (response.status === 200) {
          MySwal.fire({
            text: "Data Updated Successfully",
            icon: "success",
            confirmButtonColor: "#96887d",
            confirmButtonBorder: "#96887d",
          });
          checkProfile();
        }
        setEditMode1(false);
      })
      .catch((error) => {
        console.error("Error submitting form data:", error);
      });
  };
  const handleEditModeToggle = () => {
    setEditMode1(!editMode1); // Toggle the edit mode
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 bg-[#f0f0f0]">
      {/* Profile Section */}
      <div className="md:col-span-1 lg:col-span-1 p-4">
        <div className="bg-white shadow-md p-4 rounded-lg relative">
          <button
            // Use history.goBack() to go back to the previous page
            className="absolute top-12 right-8 text-black cursor-pointer text-[25px]"
            onClick={() => {
              navigate("/");
            }}
          >
            <MdCancel />
          </button>

          <img
            src={user.avatar}
            alt="Profile"
            className="w-40 h-40 rounded-full mx-auto"
          />
          <button
            className="top-0 pl-[230px] text-gray-600 cursor-pointer"
            onClick={handleEditClick}
          >
            <FiEdit />
          </button>
          {editMode && (
            <div className="mt-2">
              <input
                type="file"
                name="user_img"
                accept="image/*"
                onChange={handleImageUpload}
              />

              <button
                className="mt-2 bg-red-500 text-white p-2 rounded-md hover:bg-red-600"
                onClick={handleRemoveClick}
              >
                Remove
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="md:col-span-2 lg:col-span-2 p-4">
        <div className="bg-white shadow-md p-4 rounded-lg relative h-full">
          {/* Add a button to close the tab section */}
          <button
            className="absolute top-12 right-8 text-black cursor-pointer text-[25px]"
            onClick={() => {
              navigate("/");
            }}
          >
            <MdCancel />
          </button>

          <Tabs value="html" orientation="vertical">
            <TabsHeader className="w-32">
              {data.map(({ label, value }) => (
                <Tab key={value} value={value}>
                  {label}
                </Tab>
              ))}
            </TabsHeader>
            <TabsBody>
              {data.map(({ value, desc }) => (
                <TabPanel key={value} value={value} className="py-0">
                  {desc}
                </TabPanel>
              ))}
            </TabsBody>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default Profile1;
