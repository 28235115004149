import React, { useState, useEffect } from "react";
import ".././style.css";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import { useRegion } from "../Region";
import axios from "axios";

function TabsSection(productID) {
  const [activeTab, setActiveTab] = React.useState("Description");

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const { selectedRegion, setRegion } = useRegion();
  const productId = productID["productID"];

  useEffect(() => {
    getDetails();
  }, [selectedRegion, productId]);

  const getDetails = async () => {
    setIsLoading(true);
    try {
      const DetailNameResponse = await axios.get(
        `https://clientgiftgeenie.design247.in/giftgeenieapi/getProductDetails.php?productID=${productId}&regionId=${selectedRegion}`
      );
      if (DetailNameResponse.status === 200) {
        const DetailNameResponseData = DetailNameResponse.data.res;
        setData(DetailNameResponseData);
      } else if (DetailNameResponse.status === 400) {
        // Handle the 400 status if needed
      } else {
        console.log("No Data Fetched");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const productData = data.map((item) => ({
    desc: item.prod_desc,
    color: item.prod_color,
    material: item.prod_material,
    brand: item.prod_brand,
    custom: item.prod_customization,
    model: item.prod_sku,
    shippingcost: item.shipment_cost,
    contact: item.vendor_contact,
    Name: item.vendor_name,
    website: item.vendor_website,
    max: item.max_purchase_qty,
    height: item.prod_height,
    length: item.prod_length,
    weight: item.prod_weight,
    shipping: item.prod_ship_time,
  }));

  const Data = [
    {
      label: "Description",
      value: "Description",
      desc: <div>{productData[0]?.desc}</div> || "",
    },
    {
      label: "Product Info",
      value: "Product Info",
      desc: (
        <div>
          Model: {productData[0]?.model} <br />
          Brand: {productData[0]?.brand} <br />
          Color: {productData[0]?.color} <br />
          Name: {productData[0]?.Name} <br />
          Height: {productData[0]?.height}
          <br />
          Length: {productData[0]?.length} <br />
          Weight: {productData[0]?.weight} <br />
          Contact: {productData[0]?.contact} <br />
          Shipping Cost: {productData[0]?.shippingcost} <br />
          Website: {productData[0]?.website}
        </div>
      ),
    },
    {
      label: "More Info",
      value: "More Info",
      desc: (
        <div>
          Product Material: {productData[0]?.material}
          <br />
          Shipping Time: {productData[0]?.shipping} Days
          <br />
          Max Order Quantity: {productData[0]?.max}
        </div>
      ),
    },
  ];

  return (
    <Tabs value={activeTab}>
      <TabsHeader
        className="rounded-none border-b border-blue-gray-50 bg-transparent p-0"
        indicatorProps={{
          className:
            "bg-transparent border-b-2 border-gray-900 shadow-none rounded-none",
        }}
      >
        {Data.map(({ label, value }) => (
          <Tab
            key={value}
            value={value}
            onClick={() => setActiveTab(value)}
            className={activeTab === value ? "text-gray-900" : ""}
          >
            {label}
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody>
        {Data.map(({ value, desc }) => (
          <TabPanel key={value} value={value}>
            {desc}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  );
}

export default TabsSection;
