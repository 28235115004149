import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ".././style.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Navbar from "../Navbar";
import ViewTitle from "../seperatepages/viewtitle";
// import Breadcrumbs from "../seperatepages/breadcrumbs";
import { FaPlus, FaStar } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import Footer1 from "../footer1";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Breadcrumbs from "../breadcrumbs1";
import Exhi1 from "../../images/viewproducts/Exhi1.png";
import Exhi2 from "../../images/viewproducts/Exhi2.png";
import Exhi3 from "../../images/viewproducts/Exhi3.png";
import Exhi4 from "../../images/viewproducts/Exhi4.png";
import Exhi5 from "../../images/viewproducts/Exhi5.png";
import Exhi6 from "../../images/viewproducts/Exhi6.png";
import Exhi7 from "../../images/viewproducts/Exhi7.png";
import Exhi8 from "../../images/viewproducts/Exhi8.png";
import Exhi9 from "../../images/viewproducts/Exhi9.png";
import Exhi10 from "../../images/viewproducts/Exhi10.png";
import axios from "axios";
import { useRegion } from "../Region";

function Viewproducts() {
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const { sourceId } = useParams();
  const navigate = useNavigate();
  const [data, setViews] = useState([]);
  const [TitleSub, setSubtitle] = useState([]);
  const [TitleImg, setSubImage] = useState([]);
  const [initialDisplayedCards, setInitialDisplayedCards] = useState(12);
  const { selectedRegion, setRegion } = useRegion();
  const [selectedsubId, setSubId] = useState([]);
  const [openCategory, setOpenCategory] = useState(null);
  const [selectedCircleImages, setSelectedCircleImages] = useState([]);

  // State to manage selected filter

  const [selectedSortCategory, setSelectedSortCategory] = useState("Default");
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [displayedCards, setDisplayedCards] = useState(initialDisplayedCards);

  useEffect(() => {
    getViewData([]);
    getSubViewTitle();
  }, [selectedRegion, sourceId, selectedsubId]);

  const getViewData = async () => {
    setIsLoading(true);
    try {
      const ViewNameResponse = await axios.get(
        `https://clientgiftgeenie.design247.in/giftgeenieapi/getViewProducts.php?sourceId=${sourceId}&regionId=${selectedRegion}&subId=${selectedsubId}`
      );

      if (ViewNameResponse.status === 200) {
        const ViewNameResponseData = ViewNameResponse.data.res;
        setViews(ViewNameResponseData);
        setInitialDisplayedCards(ViewNameResponseData.length);
      } else {
        console.log("No Data Fetched");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setViews([]);
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getSubViewTitle = async () => {
    setIsLoading(true);
    try {
      const ViewTitleResponse = await axios.get(
        `https://clientgiftgeenie.design247.in/giftgeenieapi/getViewSubTitle.php?sourceId=${sourceId}&regionId=${selectedRegion}`
      );

      if (ViewTitleResponse.status === 200) {
        const ViewTitleResponseData = ViewTitleResponse.data.res;
        const TitleValues = ViewTitleResponseData.map((item) => ({
          Title_name: item.sub_category_name,
          Title_id: item.g_id,
          Sub_id: item.sub_id,
          sub_url: item.img_url,
        }));
        setSubtitle(TitleValues);
      } else {
        console.log("No Data Fetched");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setSubtitle([]);
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilter = (subId, text, index) => {
    const isSelected = selectedsubId.includes(subId);

    if (isSelected) {
      setSubId((prevgId) => prevgId.filter((sId) => sId !== subId));
      setOpenCategory(null);
    } else {
      setSubId((prevgId) => [...prevgId, subId]);
      setOpenCategory(subId);
    }
    const isCircleImageSelected = selectedCircleImages.some(
      (image) => image.subId === subId
    );

    if (isCircleImageSelected) {
      setSelectedCircleImages((prevImages) =>
        prevImages.filter((image) => image.subId !== subId)
      );
    } else {
      setSelectedCircleImages((prevImages) => [
        ...prevImages,
        { subId, text, index },
      ]);
    }
  };

  const handleRemoveFilter = (subId) => {
    setSubId([]);
    setOpenCategory(null);
    setSelectedCircleImages([]);
    // setSubId((prevSubId) => prevSubId.filter((sId) => sId !== subId));
    // setOpenCategory(null);

    // setSelectedCircleImages((prevImages) =>
    //   prevImages.filter((image) => image.subId !== subId)
    // );
  };

  // const circleImages = TitleSub.map((item, index) => ({
  //   text: item.Title_name,
  //   subId: item.Sub_id,
  //   src: item.sub_url
  //     ? `https://overseasgift.design247.in/${item.sub_url}`
  //     : `https://clientgiftgeenie.design247.in/giftgeenieapi/subimages/${item.Title_name}.jpg`,
  // }));

  const circleImages = TitleSub.map((item, index) => {
    const isWebp = item.sub_url && item.sub_url.toLowerCase().endsWith(".webp");
    // const encodedTitle = encodeURIComponent(
    //   item.Title_name.replace(/\s/g, " ").replace(/%2F/g, "_")
    // );

    const encodedTitle = encodeURIComponent(
      item.Title_name.replace(/\s/g, " ")
    ).replace(/%2F/g, "_");

    // Check if the URL is "https://overseasgift.design247.in/"
    const baseUrl =
      item.sub_url === "https://overseasgift.design247.in/"
        ? "https://clientgiftgeenie.design247.in/giftgeenieapi/subimages/"
        : "https://clientgiftgeenie.design247.in/giftgeenieapi/subimages/";

    return {
      text: item.Title_name,
      subId: item.Sub_id,
      src: isWebp
        ? `${baseUrl}${encodedTitle}.jpg`
        : `${baseUrl}${encodedTitle}.jpg`,
    };
  });

  const cardData = data.map((item) => ({
    src: `https://overseasgift.design247.in/${item.img_url}`,
    text: item.prod_name,
    price: item.prod_cost,
    prd_id: item.prd_id,
  }));

  const getCurrencySymbol = (region_id) => {
    switch (region_id) {
      case "1":
        return "$";
      case "2":
        return "£";
      case "3":
        return "€";
      default:
        return "€"; // Default to '$' if the region is not matched
    }
  };

  // Function to filter and sort images based on category
  const filteredImages = () => {
    let filteredData = [...cardData];

    if (selectedSortCategory === "Default") {
      return filteredData.slice(0, displayedCards);
    }

    return filteredData.slice(0, displayedCards).sort((a, b) => {
      const priceA = parseFloat(
        a.price.replace(getCurrencySymbol(selectedRegion), "")
      );
      const priceB = parseFloat(
        b.price.replace(getCurrencySymbol(selectedRegion), "")
      );

      if (selectedSortCategory === "LowToHigh") {
        return priceA - priceB;
      } else if (selectedSortCategory === "HighToLow") {
        return priceB - priceA;
      }

      return 0;
    });
  };

  const handleLoadMore = () => {
    setLoadingMore(true);

    // Simulate an asynchronous action (e.g., data fetching) using setTimeout
    setTimeout(() => {
      // Increase the number of displayed cards
      setDisplayedCards(displayedCards + 12);

      // Set loading to false when data is loaded
      setLoadingMore(false); // Set loading state for "Load More" to false
    }, 1000);
  };

  useEffect(() => {
    // Simulating an asynchronous action (e.g., image loading)
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the timeout as needed

    // Clear the timeout on component unmount
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <Navbar />
      <Breadcrumbs
        level1="View products"
        level1Path={`/viewproducts/${sourceId}`}
      />
      <ViewTitle />
      {selectedCircleImages.length > 0 && (
        <div className="flex justify-start items-center pl-[100px] mb-[-20px] md:mb-2">
          <button
            className="absolute p-1 text-[#ffffff] rounded bg-[#65451F] shadow-lg text-sm cursor-pointer"
            onClick={() => handleRemoveFilter()}
          >
            Clear Fliter
          </button>
        </div>
      )}

      <div className="flex justify-end md:-mt-12 mr-12 ">
        <select
          value={selectedSortCategory}
          onChange={(e) => setSelectedSortCategory(e.target.value)}
          className="text-sm border p-1 md:p-2 rounded-lg bg-[#f6f6f6] text-gray-500"
        >
          <option value="Default">Default</option>
          <option value="LowToHigh">Price: Low to High</option>
          <option value="HighToLow">Price: High to Low</option>
        </select>
      </div>

      <div className="w-full bg-white mx-auto sm:px-6 lg:px-14 p-4 mt-2">
        {/* Top section with circle images and text */}
        <div className="flex flex-wrap md:flex-nowrap justify-around overflow-x-auto w-full">
          {/* {TitleSub.length > 0 &&} */}
          {circleImages.map((item, index) => (
            <div
              key={index}
              className={`flex flex-col items-center p-1 mx-auto w-full ${
                selectedCircleImages.some((image) => image.subId === item.subId)
                  ? "border-4 border-[#65451F] rounded-lg"
                  : ""
              }`}
              style={{ maxWidth: "150px" }}
            >
              <a onClick={() => handleFilter(item.subId, item.text, index)}>
                <img
                  src={item.src}
                  alt={item.text}
                  className="rounded-full w-20 h-20 object-cover transition-transform duration-300 transform scale-100 hover:scale-110 cursor-pointer"
                />
              </a>

              <p className="mt-2 text-[12px] text-center">{item.text}</p>
              {/* {selectedCircleImages.some(
                (image) => image.subId === item.subId
              ) && (
                <button
                  className="left-3 p-1 text-[#AFAFAF] rounded bg-black shadow-lg text-sm cursor-pointer"
                  onClick={() => handleRemoveFilter(item.subId)}
                >
                  <RxCross2 />
                </button>
              )} */}
            </div>
          ))}
        </div>

        {/* Bottom section with cards */}

        <div className="mt-8 grid grid-cols-2 md:grid-cols-4 gap-6">
          {loading || loadingMore ? (
            // Loading Skeleton
            Array.from({ length: initialDisplayedCards }).map((_, index) => (
              <div key={index} className="skeleton-card">
                <Skeleton height={300} />
                <Skeleton height={20} width={80} style={{ marginTop: 10 }} />
              </div>
            ))
          ) : data.length === 0 ? ( // Check if data array is empty
            <div className="flex justify-center items-center text-[16px] h-full text-gray-600">
              No products found in this region.
            </div>
          ) : (
            filteredImages().map((item, index) => (
              <div
                key={item.prd_id}
                className="relative group cursor-pointer grid grid-cols-1   md:grid-cols-1 gap-4"
                onClick={() => {
                  navigate(
                    `/viewproducts/${sourceId}/ProductDetailsPage/${item.prd_id}`
                  );
                }}
              >
                <div
                  key={index}
                  className="bg-white border-[1px] border-[#ccc] rounded-xl  overflow-hidden transition duration-300 transform group-hover:scale-105"
                >
                  <div className="w-full h-340 mx-auto md:h-[340px] md:W-[100px]">
                    <img
                      src={item.src}
                      alt={item.text}
                      loading="lazy"
                      className="w-full h-fullobject-cover rounded-t-xl"
                    />

                    <FaPlus className="absolute top-1 right-1 p-2 text-[#AFAFAF] rounded-bl-lg  rounded-tr-lg bg-white shadow-lg text-4xl block" />
                    <p className="text-gray-600 top-2 text-center p-4 text-[14px]">
                      {item.text}
                    </p>
                  </div>

                  <div className="flex justify-center">
                    <p className="text-[14px] text-center text-black p-4 -mt-5">
                      {getCurrencySymbol(selectedRegion)} {item.price}
                    </p>
                    {/* <span className="text-lg text-gray-600 line-through -mt-5">
                          {item.disprice}
                        </span> */}
                  </div>

                  {/* <div className="flex items-center p-4">
                        <span className="text-gray-600 text-[14px] -mt-5">
                          {item.rating}
                        </span>
                        <FaStar className="text-[#fdb913] ml-1 w-3 h-3 -mt-5" />
                        <span className="text-gray-600 ml-2 text-[14px] -mt-5">
                          {item.left}
                        </span>
                      </div> */}
                </div>
              </div>
            ))
          )}
        </div>

        {/* Load More Button */}
        {displayedCards < cardData.length && (
          <div className="flex justify-center mt-4">
            <button
              className="py-[5px] px-[20px] font-[16px] rounded-lg bg-[#65451F] hover:bg-[#937c62] text-white hover:text-white transition-colors 0.3s"
              onClick={handleLoadMore}
            >
              {loadingMore ? "Loading..." : "Load More"}
            </button>
          </div>
        )}
      </div>
      <Footer1 />
    </div>
  );
}

export default Viewproducts;
