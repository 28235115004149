// ProductDetailsPage.js

import React from "react";
import ProductCheckoutPage from "./productcheckout";
import Breadcrumbs from "../breadcrumbs1";
import Navbar from "../Navbar";
import Footer1 from "../footer1";
import { useParams } from "react-router-dom";

const ProductCheckoutpage = () => {
  const { sourceId, prd_id } = useParams();
  return (
    <div>
      <Navbar />
      <Breadcrumbs
        level1="Product Catalogue"
        level1Path="/ProductCheckoutpage"
      />
      <div className="flex mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <ProductCheckoutPage />
      </div>
      <Footer1 />
    </div>
  );
};

export default ProductCheckoutpage;
