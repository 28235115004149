// src/App.js

import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { FooterWithSocialLinks } from "./footer";
import Breadcrumbs from "./breadcrumbs1";
import AOS from "aos";
import "aos/dist/aos.css";
import "./style.css";
import AboutUsImage from "../images/AboutUs/aboutus2.jpg";

const AboutDetail = () => {
  useEffect(() => {
    AOS.init({ duration: "2000", offset: 0, delay: 500 });
  }, []);

  return (
    <div>
      <Navbar />
      <Breadcrumbs level1="AboutUs Detail" level1Path={`/aboutdetail`} />
      <div className="flex flex-col-reverse lg:flex-row bg-white">
        <div className="w-full lg:w-1/2 p-4 lg:p-8" data-aos="slide-right">
          <img
            src={AboutUsImage} // Replace with your image URL
            alt="Description"
            className="w-full h-auto rounded-md"
          />
        </div>
        <div
          className="w-full lg:w-1/2 p-4 lg:p-8 flex flex-col justify-center"
          data-aos="slide-left"
        >
          <div className="mb-4">
            <h2 className="text-2xl lg:text-3xl font-bold mb-2">
              Image Description 1
            </h2>
            <p className="text-gray-700">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </div>
          <div className="mb-4">
            <p className="text-gray-700">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </div>
          <div>
            <p className="text-gray-700">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </div>
        </div>
      </div>
      <FooterWithSocialLinks />
    </div>
  );
};

export default AboutDetail;
