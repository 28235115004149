import React, { createContext, useContext, useState, useEffect } from "react";

const RegionContext = createContext();

export const RegionProvider = ({ children }) => {
  const [selectedRegion, setSelectedRegion] = useState(null);

  // const setRegion = (region) => {
  //   setSelectedRegion(region);
  // };

  useEffect(() => {
    // Function to get the stored region from session storage
    const getStoredRegion = () => {
      const storedRegion = sessionStorage.getItem("selectedRegion");
      return storedRegion ? parseInt(storedRegion, 10) : null;
    };

    // Function to set the default region to session storage
    const setDefaultRegion = () => {
      const defaultRegion = 3; // Set your default region ID here
      sessionStorage.setItem("selectedRegion", defaultRegion);
      setSelectedRegion(defaultRegion);
    };

    // Check if the region is already set in session storage
    const storedRegionId = getStoredRegion();

    if (storedRegionId !== null) {
      // Use the stored region if available
      setSelectedRegion(storedRegionId);
    } else {
      // Set the default region and save it to session storage
      setDefaultRegion();
    }
  }, []);

  const setRegion = (region) => {
    sessionStorage.setItem("selectedRegion", region);
    setSelectedRegion(region);
  };

  return (
    <RegionContext.Provider value={{ selectedRegion, setRegion }}>
      {children}
    </RegionContext.Provider>
  );
};

export const useRegion = () => {
  const context = useContext(RegionContext);
  if (!context) {
    throw new Error("useRegion must be used within a RegionProvider");
  }
  return context;
};
