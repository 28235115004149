// Profile.js
import React, { useState, useEffect } from "react";
import Avatar from "../images/Login/avatar.png";
import Background from "../images/Login/profilebackground.jpg";
import { FiEdit } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import { FaUserEdit } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { GiCancel } from "react-icons/gi";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";

const Profile = () => {
  const MySwal = withReactContent(Swal);
  const [isLoading, setIsLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [editMode1, setEditMode1] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [file, setFile] = useState(null);
  const navigate = useNavigate();

  const location = useLocation();
  const userEmail =
    location.state?.userEmail || localStorage.getItem("userEmail");

  const [user, setUser] = useState({
    name: "John Doe",
    email: "john@example.com",
    avatar: Avatar,
  });

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleRemoveClick = async (userEmail) => {
    // setUser({
    //   ...user,
    //   avatar: Avatar, // Set the avatar back to the default value or null if needed
    // });
    // setEditMode(false);

    try {
      const response = await axios.post(
        "https://clientgiftgeenie.design247.in/giftgeenieapi/removeProfileImage.php",
        userEmail
      );
      if (response.status === 200) {
        setUser({
          ...user,
          avatar: Avatar, // Set the avatar back to the default value
        });
        setEditMode(false);
      } else {
        console.error("Error removing profile image:", response.data.message);
      }
    } catch (error) {
      // Handle errors
      console.error("Error removing profile image:", error);
    }
  };

  const handleImageUpload = async (e) => {
    const selectedFile = e.target.files[0];

    const formData = new FormData();
    formData.append("user_img", selectedFile);
    formData.append("user_email", userEmail); // Add this line

    console.log(formData);

    try {
      const response = await axios.post(
        "https://clientgiftgeenie.design247.in/giftgeenieapi/updateProfileImage.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        const updatedUser = response.data;
        console.log("Updated User:", updatedUser);
        setUser((prevUser) => ({
          ...prevUser,
          avatar: `https://clientgiftgeenie.design247.in/giftgeenieapi/${updatedUser.avatar}`,
        }));
        localStorage.setItem("userAvatar", updatedUser.avatar);
        setEditMode(false);
      } else {
        console.error("Error uploading image:", response.data.message);
      }
    } catch (error) {
      // Handle errors
      console.error("Error uploading image:", error);
    }
  };

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    user_email: "",
    user_pass: "",
    user_phone: "",
    user_address: "",
    company_name: "",
    company_address: "",
    city: "",
    state: "",
    country: "",
    region: 0,
    user_img: "",
  });

  // const handleEditClick = () => {
  //   setEditMode(!editMode);
  // };

  // const handleRemoveClick = () => {
  //   // Handle image removal logic here
  //   console.log("Image removed");
  //   setEditMode(false); // Exit edit mode
  // };

  useEffect(() => {
    checkProfile();
  }, [userEmail]);

  const checkProfile = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(
        `https://clientgiftgeenie.design247.in/giftgeenieapi/getProfile.php?user_email=${userEmail}`
      );

      if (response.status === 200) {
        const responseData = response.data.res[0];
        console.log(responseData);
        const imageUrl = responseData.user_img;

        if (imageUrl) {
          setUser((prevUser) => ({
            ...prevUser,
            avatar: `https://clientgiftgeenie.design247.in/giftgeenieapi/${imageUrl}`,
          }));
        } else {
          console.error("user_img is undefined in responseData");
        }
        setFormData(responseData);
      } else {
        console.error("Failed to check authentication");
      }
    } catch (error) {
      console.error("Error checking authentication:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Password validation
    if (name === "user_pass") {
      setPasswordVisible(false); // Hide password when editing
      const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
      if (!passwordRegex.test(value) && value !== "") {
        // Password should be at least 8 characters long and contain at least one letter and one number
        setFormSubmitted(false);
      }
    }

    // Phone validation
    if (name === "user_phone") {
      const phoneRegex = /^\d+$/;
      if (!phoneRegex.test(value) && value !== "") {
        // Phone should only contain numbers
        setPhoneError(true);
      } else {
        setPhoneError(false);
      }
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    axios
      .post(
        "https://clientgiftgeenie.design247.in/giftgeenieapi/updateProfileDeatils.php",
        formData
      )
      .then((response) => {
        if (response.status === 200) {
          MySwal.fire({
            text: "Data Updated Successfully",
            icon: "success",
            confirmButtonColor: "#96887d",
            confirmButtonBorder: "#96887d",
          });
          checkProfile();
        }
        setEditMode1(false);
      })
      .catch((error) => {
        console.error("Error submitting form data:", error);
      });
  };
  const handleEditModeToggle = () => {
    setEditMode1(!editMode1); // Toggle the edit mode
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4  bg-[#f0f0f0]">
      {/* Profile Section */}
      <div className="md:col-span-1 lg:col-span-1 p-4">
        <div className="bg-white shadow-md p-4 rounded-lg">
          <button
            // Use history.goBack() to go back to the previous page
            className="absolute top-12 right-8 text-black cursor-pointer text-[25px]"
            onClick={() => {
              navigate("/");
            }}
          >
            <MdCancel />
          </button>

          <img
            src={user.avatar}
            alt="Profile"
            className="w-40 h-40 rounded-full mx-auto"
          />
          <button
            className="top-0 pl-[230px] text-gray-600 cursor-pointer"
            onClick={handleEditClick}
          >
            <FiEdit />
          </button>
          {editMode && (
            <div className="mt-2">
              <input
                type="file"
                name="user_img"
                accept="image/*"
                onChange={handleImageUpload}
              />

              <button
                className="mt-2 bg-red-500 text-white p-2 rounded-md hover:bg-red-600"
                onClick={handleRemoveClick}
              >
                Remove
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Form Section */}
      <div className="md:col-span-1 lg:col-span-2 p-4">
        <div className="bg-white p-4 rounded-lg shadow-md">
          <p className="text-[35px] ">Your Profile</p>
          <form className="grid grid-cols-1 gap-4">
            <div className="grid grid-cols-2 md:grid-cols-2 gap-4">
              <div>
                <button
                  type="button"
                  onClick={handleEditModeToggle}
                  className="text-sm font-semibold rounded-md text-white bg-[#96887d] px-3 py-2 border border-1 border-black-600 ml-2 mr-2"
                >
                  {editMode1 ? "Cancel" : <FaUserEdit />}
                </button>
                {editMode1 && (
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="rounded-md bg-[#C8AE7D] px-3 py-2 text-sm font-semibold text-white shadow-sm"
                  >
                    Update
                  </button>
                )}
              </div>
            </div>
            {/* First row: First Name, Last Name, Email */}
            <div className="grid grid-cols-2 md:grid-cols-2 gap-4">
              <div className="sm:col-span-1">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-600"
                >
                  First Name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="firstName"
                    name="first_name"
                    disabled={!editMode1}
                    value={formData.first_name}
                    onChange={handleChange}
                    className="mt-1 p-2 border border-[#c8ae7d] rounded-md w-full ring-1 ring-inset ring-[#c8ae7d] focus:ring-1 focus:ring-inset focus:ring-[#c8ae7d] focus:outline-none focus:shadow-[#c8ae7d] active:border-[#c8ae7d] active:outline-none active:shadow-[#c8ae7d]"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-600"
                >
                  Last Name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="lastName"
                    name="last_name"
                    disabled={!editMode1}
                    value={formData.last_name}
                    onChange={handleChange}
                    className="mt-1 p-2 border border-[#c8ae7d] rounded-md w-full ring-1 ring-inset ring-[#c8ae7d] focus:ring-1 focus:ring-inset focus:ring-[#c8ae7d] focus:outline-none focus:shadow-[#c8ae7d] active:border-[#c8ae7d] active:outline-none active:shadow-[#c8ae7d]"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-600"
                >
                  Email Id
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="email"
                    name="user_email"
                    disabled
                    value={formData.user_email}
                    onChange={handleChange}
                    className="mt-1 p-2 border border-[#c8ae7d] rounded-md w-full ring-1 ring-inset ring-[#c8ae7d] focus:ring-1 focus:ring-inset focus:ring-[#c8ae7d] focus:outline-none focus:shadow-[#c8ae7d] active:border-[#c8ae7d] active:outline-none active:shadow-[#c8ae7d]"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-600"
                >
                  Password
                </label>
                <div className="mt-2">
                  <input
                    type={passwordVisible ? "text" : "password"}
                    id="password"
                    name="user_pass"
                    disabled={!editMode1}
                    value={formData.user_pass}
                    onChange={handleChange}
                    className="mt-1 p-2 border border-[#c8ae7d] rounded-md w-full ring-1 ring-inset ring-[#c8ae7d] focus:ring-1 focus:ring-inset focus:ring-[#c8ae7d] focus:outline-none focus:shadow-[#c8ae7d] active:border-[#c8ae7d] active:outline-none active:shadow-[#c8ae7d]"
                  />
                  {editMode1 && (
                    <span
                      onClick={() => setPasswordVisible(!passwordVisible)}
                      className="absolute top-1/5 right-10 transform -translate-y-[-15px] cursor-pointer"
                    >
                      {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  )}
                </div>
                {/* {formSubmitted && !passwordVisible && (
                  <p className="text-red-500 text-sm mt-1">
                    Password should be at least 6 characters long and contain at
                    least one letter and one number.
                  </p>
                )} */}
              </div>
              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-600"
                >
                  Phone No
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="phone"
                    name="user_phone"
                    maxLength={10}
                    disabled={!editMode1}
                    value={formData.user_phone}
                    onChange={handleChange}
                    className={`mt-1 p-2 border border-[#c8ae7d] rounded-md w-full ring-1 ring-inset ring-[#c8ae7d] focus:ring-1 focus:ring-inset focus:ring-[#c8ae7d] focus:outline-none focus:shadow-[#c8ae7d] active:border-[#c8ae7d] active:outline-none active:shadow-[#c8ae7d] ${
                      phoneError ? "border-red-500" : ""
                    }`}
                  />
                  {phoneError && (
                    <p className="text-red-500 text-sm mt-1">
                      Only numbers are allowed for phone.
                    </p>
                  )}
                </div>
              </div>
              <div>
                <label
                  htmlFor="address"
                  className="block text-sm font-medium text-gray-600"
                >
                  Address
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="address"
                    name="user_address"
                    disabled={!editMode1}
                    value={formData.user_address}
                    onChange={handleChange}
                    className="mt-1 p-2 border border-[#c8ae7d] rounded-md w-full ring-1 ring-inset ring-[#c8ae7d] focus:ring-1 focus:ring-inset focus:ring-[#c8ae7d] focus:outline-none focus:shadow-[#c8ae7d] active:border-[#c8ae7d] active:outline-none active:shadow-[#c8ae7d]"
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
              <div>
                <label
                  htmlFor="companyname"
                  className="block text-sm font-medium text-gray-600"
                >
                  Company Name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="companyname"
                    name="company_name"
                    disabled={!editMode1}
                    value={formData.company_name}
                    onChange={handleChange}
                    className="mt-1 p-2 border border-[#c8ae7d] rounded-md w-full ring-1 ring-inset ring-[#c8ae7d] focus:ring-1 focus:ring-inset focus:ring-[#c8ae7d] focus:outline-none focus:shadow-[#c8ae7d] active:border-[#c8ae7d] active:outline-none active:shadow-[#c8ae7d]"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="companyaddress"
                  className="block text-sm font-medium text-gray-600"
                >
                  Company Address
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="companyaddress"
                    name="company_address"
                    disabled={!editMode1}
                    value={formData.company_address}
                    onChange={handleChange}
                    className="mt-1 p-2 border border-[#c8ae7d] rounded-md w-full ring-1 ring-inset ring-[#c8ae7d] focus:ring-1 focus:ring-inset focus:ring-[#c8ae7d] focus:outline-none focus:shadow-[#c8ae7d] active:border-[#c8ae7d] active:outline-none active:shadow-[#c8ae7d]"
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-2 gap-4">
              <div>
                <label
                  htmlFor="city"
                  className="block text-sm font-medium text-gray-600"
                >
                  City
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="city"
                    name="city"
                    disabled={!editMode1}
                    value={formData.city}
                    onChange={handleChange}
                    className="mt-1 p-2 border border-[#c8ae7d] rounded-md w-full ring-1 ring-inset ring-[#c8ae7d] focus:ring-1 focus:ring-inset focus:ring-[#c8ae7d] focus:outline-none focus:shadow-[#c8ae7d] active:border-[#c8ae7d] active:outline-none active:shadow-[#c8ae7d]"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="state"
                  className="block text-sm font-medium text-gray-600"
                >
                  State
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="state"
                    name="state"
                    disabled={!editMode1}
                    value={formData.state}
                    onChange={handleChange}
                    className="mt-1 p-2 border border-[#c8ae7d] rounded-md w-full ring-1 ring-inset ring-[#c8ae7d] focus:ring-1 focus:ring-inset focus:ring-[#c8ae7d] focus:outline-none focus:shadow-[#c8ae7d] active:border-[#c8ae7d] active:outline-none active:shadow-[#c8ae7d]"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="country"
                  className="block text-sm font-medium text-gray-600"
                >
                  Country
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="country"
                    name="country"
                    disabled={!editMode1}
                    value={formData.country}
                    onChange={handleChange}
                    className="mt-1 p-2 border border-[#c8ae7d] rounded-md w-full ring-1 ring-inset ring-[#c8ae7d] focus:ring-1 focus:ring-inset focus:ring-[#c8ae7d] focus:outline-none focus:shadow-[#c8ae7d] active:border-[#c8ae7d] active:outline-none active:shadow-[#c8ae7d]"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="region"
                  className="block text-sm font-medium text-gray-600"
                >
                  Region
                </label>
                <div className="mt-2">
                  <select
                    id="region"
                    name="region"
                    disabled={!editMode1}
                    value={formData.region}
                    onChange={handleChange}
                    className="mt-1 p-2 border border-[#c8ae7d] rounded-md w-full ring-1 ring-inset ring-[#c8ae7d] focus:ring-1 focus:ring-inset focus:ring-[#c8ae7d] focus:outline-none focus:shadow-[#c8ae7d] active:border-[#c8ae7d] active:outline-none active:shadow-[#c8ae7d]"
                  >
                    <option value={0} disabled>
                      Select a region
                    </option>
                    <option value={1}>USA</option>
                    <option value={2}>UK</option>
                    <option value={3}>EUROPE</option>
                  </select>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Profile;
