import React from 'react'
import { useParams } from 'react-router-dom'
import Footer1 from '../../components/footer1'
import Navbar from '../../components/Navbar'
import UserSidebar from '../../components/UserProfile/UserSidebar'
import AccountSettings from '../../components/UserProfile/AccountSettings'
import './UserProfile.css'
import ChangePassword from '../../components/UserProfile/ChangePassword'
import YourOrders from '../../components/UserProfile/YourOrders'
import UserAddress from '../../components/UserProfile/UserAddress'
import LegalNotice from '../../components/UserProfile/LegalNotice'
import Breadcrumbs from "../../components/breadcrumbs1";

const UserProfile = () => {

    const {activepage} = useParams()

  return (
    <div className='userprofile'>
        <Navbar/>      
      <Breadcrumbs
        level1="User Profile"
        level1Path={`/user/:activepage`}
      />
         <div className='userprofilein'>
            <div className='left'>
              <UserSidebar activepage={activepage}/>
            </div>
            <div className='right'>
              {activepage === 'accountsettings' && <AccountSettings/>}
              {/* {activepage === 'changepassword' && <ChangePassword/>}
              {activepage === 'yourorders' && <YourOrders/>} */}
              {activepage === 'address' && <UserAddress/>}
              {activepage === 'legalnotice' && <LegalNotice/>}
            </div>
         </div>
        <Footer1/>
    
        </div>
  )
}

export default UserProfile