// FloatingIcon.js

import React, { useState } from "react";
import whatsapp1 from "../images/whatsapp/whatsapp-144.png";

const FloatingIcon = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleIconClick = () => {
    // Replace 'your-whatsapp-number' with the actual WhatsApp number
    const whatsappNumber = "+1 (234) 567-890";
    const whatsappUrl = `https://wa.me/${whatsappNumber}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div
      className={`fixed bottom-[55px] right-0 cursor-pointer transition-transform transform ${
        isHovered ? "scale-110" : ""
      }`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleIconClick}
    >
      {/* WhatsApp icon */}
      <img
        src={whatsapp1}
        alt="WhatsApp Icon"
        className="w-10 h-10 object-cover"
      />
    </div>
  );
};

export default FloatingIcon;
