// ProductDetailsPage.js

import React, { useState, useEffect } from "react";
import ThumbnailGallery from "./ThumbnailGallery";
import ProductHeading from "./ProductHeading";
import AccordionSection from "./AccordionSection";
import TabsSection from "./TabsSection";
import Breadcrumbs from "../breadcrumbs1";
import Navbar from "../Navbar";
import { useParams } from "react-router-dom";
import Footer1 from "../footer1";
import { useRegion } from "../Region";
import axios from "axios";

const ProductDetailsPage = () => {
  const { sourceId, prd_id, Page } = useParams();
  const productId = prd_id;
  const { selectedRegion, setRegion } = useRegion();
  const [isFlag, setIsFlag] = useState(true);
  const storedRegion = sessionStorage.getItem("selectedRegion");

  useEffect(() => {
    getDetails();
  }, [selectedRegion, storedRegion, productId]);

  const getDetails = async () => {
    try {
      const DetailNameResponse = await axios.get(
        `https://clientgiftgeenie.design247.in/giftgeenieapi/getProductDetails.php?productID=${productId}&regionId=${selectedRegion}`
      );
      if (DetailNameResponse.status === 200) {
        setIsFlag(true);
      } else if (DetailNameResponse.status === 400) {
        setIsFlag(false);
      } else {
        console.log("No Data Fetched");
        setIsFlag(false);
      }
    } catch (error) {
      console.log(error);
      setIsFlag(false);
    }
  };

  return (
    <>
      <div>
        <Navbar />
        <Breadcrumbs
          level1={`${Page}`}
          level1Path={`/${Page}${sourceId !== undefined ? `/${sourceId}` : ""}`}
          level2="Product Details"
          level2Path={`/ProductDetailsPage/${prd_id}`}
        />
        {isFlag ? (
          <div className="flex flex-col mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="w-full md:flex">
              {/* Left Section - Thumbnail Gallery */}
              <div className="w-full md:w-2/4 pr-8">
                <ThumbnailGallery productID={productId} />
              </div>

              {/* Right Section - Product Heading, Accordion, Tabs */}
              <div className="w-full p-4 md:w-2/4 md:p-0">
                <ProductHeading productID={productId} />

                {/* Tabs Section */}
                <TabsSection productID={productId} />

                {/* Accordion Section */}
                {/* <AccordionSection productID={productId} /> */}
              </div>
            </div>
          </div>
        ) : (
          <p className="text-3xl text-black font-semibold pl-10">
            No Product Found In This Region
          </p>
        )}
        <Footer1 />
      </div>
    </>
  );
};

export default ProductDetailsPage;
