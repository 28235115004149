import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./style.css";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import News1 from "../images/Categories/News01.jpg";
import News2 from "../images/Categories/news02.jpg";

export function CarouselCustomNavigation1() {
  const MySwal = withReactContent(Swal);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const totalImages = 2;

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    user_email: "",
    Message: "",
  });

  const resetForm = () => {
    setFormData({
      first_name: "",
      last_name: "",
      user_email: "",
      Message: "",
    });
  };

  const autoAdvanceCarousel = () => {
    const nextIndex = (activeIndex + 1) % totalImages;
    setActiveIndex(nextIndex);
  };

  useEffect(() => {
    const interval = setInterval(autoAdvanceCarousel, 5000);
    return () => clearInterval(interval);
  }, [activeIndex]);

  const carouselImages = [
    {
      image: News1,
    },
    {
      image: News2,
    },
  ];

  const userEnquiry = async () => {
    setIsLoading(true);

    // Check if any of the required fields are empty
    if (
      !formData.first_name ||
      !formData.last_name ||
      !formData.user_email ||
      !formData.Message
    ) {
      setIsLoading(false);

      // Show SweetAlert for empty fields
      MySwal.fire({
        icon: "error",
        text: "FirstName & Email is required.",
        confirmButtonColor: "#96887d",
        confirmButtonBorder: "#96887d",
      });

      return; // Do not proceed with the enquiry if any field is empty
    }

    // Check if the email is valid
    if (!isValidEmail(formData.user_email)) {
      setIsLoading(false);

      // Show SweetAlert for invalid email
      MySwal.fire({
        icon: "error",
        title: "Invalid Email",
        text: "Please enter a valid email address.",
        confirmButtonColor: "#96887d",
        confirmButtonBorder: "#96887d",
      });

      return; // Do not proceed with the enquiry if the email is invalid
    }

    try {
      const userResponse = await axios.post(
        "https://clientgiftgeenie.design247.in/giftgeenieapi/postEnquiryData.php",
        formData
      );

      if (userResponse.status === 200) {
        resetForm();

        // Show SweetAlert
        MySwal.fire({
          icon: "success",
          title: "Enquiry Raised Sucessfully!",
          text: "Our Team You Contact you Soon",
          confirmButtonColor: "#96887d",
          confirmButtonBorder: "#96887d",
        }).then(() => {
          SendEnquireMail(formData.user_email);
          closeModal(); // Close modal after submitting
        });
      } else {
        console.error("Failed to add the Data");
      }
    } catch (error) {
      console.error("Failed to add the Data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const isValidEmail = (email) => {
    // Use a regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const SendEnquireMail = async (userEmail) => {
    setIsLoading(true);

    try {
      const response = await axios.get(
        `https://clientgiftgeenie.design247.in/giftgeenieapi/getEnquireEmail.php?user_email=${userEmail}`
      );

      if (response.status === 200) {
      } else {
        console.error("Failed to Send Mail");
      }
    } catch (error) {
      console.error("Failed to Send Mail:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="carousel-container mx-auto max-w-7xl px-2 sm:px-6 lg:px-10 mb-8">
      <div className="overflow-hidden border-[4px] border-[#65451F]">
        <Carousel
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          dynamicHeight={true}
          selectedItem={activeIndex}
          onChange={(index) => setActiveIndex(index)}
          emulateTouch={true}
          infiniteLoop={true}
          useKeyboardArrows={true}
          interval={5000} // Interval in milliseconds for automatic sliding
          showArrows={false}
          onClickItem={openModal} // Open modal on image click
        >
          {carouselImages.map((item, index) => (
            <div key={index}>
              <div className="relative cursor-pointer">
                <img
                  src={item.image}
                  alt={`image ${index + 1}`}
                  className="object-cover color-[#65451F]"
                />
              </div>
            </div>
          ))}
        </Carousel>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 z-10 overflow-y-auto mt-20">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <form className="grid grid-cols-1 gap-4">
                  <div className="relative text-[#65451F]">
                    <b>Get In Touch</b>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {/* Add your form fields here */}
                    <div className="relative mb-4 md:mb-0">
                      <input
                        type="text"
                        id="floating_outlined"
                        name="firstname"
                        value={formData.first_name}
                        onChange={(e) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            first_name: e.target.value,
                          }))
                        }
                        class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-[#96887d] focus:outline-none focus:ring-0 focus:border-[#96887d] peer"
                        placeholder=" "
                      />
                      <label
                        for="floating_outlined"
                        class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-[#65451F] peer-focus:dark:text-[#65451F] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        First Name
                      </label>
                    </div>

                    <div class="relative">
                      <input
                        type="text"
                        id="floating_outlined"
                        name="lastname"
                        value={formData.last_name}
                        onChange={(e) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            last_name: e.target.value,
                          }))
                        }
                        class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-[#96887d] focus:outline-none focus:ring-0 focus:border-[#96887d] peer"
                        placeholder=" "
                      />
                      <label
                        for="floating_outlined"
                        class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-[#65451F] peer-focus:dark:text-[#65451F] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        Last Name
                      </label>
                    </div>

                    <div className="relative col-span-2">
                      <input
                        type="text"
                        id="floating_outlined"
                        name="email"
                        value={formData.user_email}
                        onChange={(e) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            user_email: e.target.value,
                          }))
                        }
                        class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-[#96887d] focus:outline-none focus:ring-0 focus:border-[#96887d] peer"
                        placeholder=" "
                      />
                      <label
                        for="floating_outlined"
                        class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-[#65451F] peer-focus:dark:text-[#65451F] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        {formData.user_email !== ""
                          ? isValidEmail(formData.user_email)
                            ? "Email"
                            : "Invalid Email"
                          : "Email"}
                      </label>
                    </div>

                    <div class="relative col-span-2">
                      <textarea
                        type="text"
                        id="floating_outlined"
                        name="Message"
                        value={formData.Message}
                        onChange={(e) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            Message: e.target.value,
                          }))
                        }
                        class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-[#96887d] focus:outline-none focus:ring-0 focus:border-[#96887d] peer"
                        placeholder=" "
                      />
                      <label
                        for="floating_outlined"
                        class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-[#65451F] peer-focus:dark:text-[#65451F] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        Message
                      </label>
                    </div>
                  </div>
                </form>

                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    onClick={() => {
                      userEnquiry();
                    }}
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#65451F] hover:bg-[#937c62] text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#65451F] sm:ml-3 sm:w-auto sm:text-sm mb-5 mt-2 md:mb-0 md:mt-0"
                  >
                    Submit
                  </button>
                  <button
                    onClick={closeModal}
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#65451F] hover:bg-[#937c62] text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#65451F] sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
