import React from "react";
import "./DropdownItem.css";
import { useNavigate } from "react-router-dom";

const DropdownItem = (props) => {
  const navigate = useNavigate();

  const handleItemClick = () => {
    if (props.setActive) {
      props.setActive(props.id);
    }

    // if (props.id) {
    //   navigate(`/viewproducts/${props.id}`);
    // }
  };

  return (
    <>
      <a className="menu-item" onClick={handleItemClick}>
        <span className="icon-button">{props.leftIcon}</span>
        {props.children}
      </a>
    </>
  );
};

export default DropdownItem;
