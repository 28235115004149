// src/ProductCheckoutPage.js
import React, { useState, useEffect } from "react";
import Exhibition2 from "../../images/viewproducts/Exhi2 - Copy.png";
import Exhibition3 from "../../images/viewproducts/Exhi3 - Copy.png";
import { MdDeleteForever } from "react-icons/md";
import Cookies from "js-cookie";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
import { BsCartX } from "react-icons/bs";

const ProductCheckoutPage = () => {
  const [products, setProducts] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [ischeck, setIscheck] = useState([]);
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const storedUserId = localStorage.getItem("userId");

  useEffect(() => {
    getCheckoutData();
  }, []);

  const getCheckoutData = async () => {
    setIsLoading(true);
    try {
      const CheckoutResponse = await axios.get(
        `https://clientgiftgeenie.design247.in/giftgeenieapi/getcheckoutDetails.php?user_Id=${storedUserId}`
      );
      if (CheckoutResponse.status === 200) {
        const CheckoutResponseData = CheckoutResponse.data.res;
        setIscheck(CheckoutResponseData);
      } else {
        console.log("No Data Fetched");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setIscheck([]);
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  let mappedData = [];
  if (ischeck && ischeck.length > 0) {
    mappedData = ischeck.map((item) => ({
      src: `https://overseasgift.design247.in/${item.prod_img}`,
      text: item.prod_name,
      price: parseFloat(item.prod_cost),
      prd_id: item.prod_id,
      prd_qty: item.prod_qty,
      currency: item.prod_currency,
    }));
  } else {
    mappedData = (
      <>
        <div className="mx-4 md:mr-[24%] md:hidden">
          <div className="flex justify-center">
            <div className="rounded-lg p-4 bg-white shadow-md">
              <BsCartX fontSize="3rem" color="#96887d" />
            </div>
          </div>
          <div className="flex justify-center text-[22px] md:mr-[-6%]">
            <h4>Your Cart Is Empty</h4>
          </div>
        </div>

        <div className="mx-4 md:mr-[24%] hidden md:block">
          <div className="flex justify-end">
            <div className="rounded-lg p-4 bg-white shadow-md">
              <BsCartX fontSize="5rem" color="#96887d" />
            </div>
          </div>
          <div className="flex justify-end text-[25px] md:mr-[-6%]">
            <h4>Your Cart Is Empty</h4>
          </div>
        </div>

        <div className="mt-4 md:hidden">
          <div className="text-[14px] text-center">
            Kindly Log In to view your products
          </div>
        </div>

        <div className="hidden md:block">
          <div className="text-[18px] text-center ml-[38%]">
            <div>Kindly Log In to view your products</div>
          </div>
        </div>
      </>
    );
  }

  const getIncreaseqty = async (productId) => {
    console.log(productId);
    setIsLoading(true);
    try {
      const IncreaseResponse = await axios.get(
        `https://clientgiftgeenie.design247.in/giftgeenieapi/getPlusqty.php?user_Id=${storedUserId}&productID=${productId}`
      );

      if (IncreaseResponse.status === 200) {
        getCheckoutData();
      } else {
        console.log("No Data Fetched");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getDecreaseqty = async (productId) => {
    console.log(productId);
    setIsLoading(true);
    try {
      const IncreaseResponse = await axios.get(
        `https://clientgiftgeenie.design247.in/giftgeenieapi/getMinusqty.php?user_Id=${storedUserId}&productID=${productId}`
      );
      if (IncreaseResponse.status === 200) {
        getCheckoutData();
      } else {
        console.log("No Data Fetched");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const deleteProduct = (productId) => {
    Swal.fire({
      title: "Are you sure you need to remove this product?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Handle the deletion logic here
        Swal.fire({
          icon: "success",
          title: "Deleted!",
          text: "Your item has been deleted.",
          confirmButtonColor: "#96887d",
          confirmButtonBorder: "#96887d",
        });
        try {
          const cartDeleteResponse = await axios.get(
            `https://clientgiftgeenie.design247.in/giftgeenieapi/deleteCart.php?productId=${productId}&userId=${storedUserId}`
          );

          if (cartDeleteResponse.status === 200) {
            // Show a success message using sweetalert2
            MySwal.fire({
              icon: "success",
              title: "Item Removed",
              text: "The product has been removed from your cart.",
              confirmButtonColor: "#96887d",
              confirmButtonBorder: "#96887d",
            });
            getCheckoutData();
          } else {
            // Handle other status codes or errors
            console.error("Failed to add the product to the cart");
          }
        } catch (error) {
          console.error("Error adding product to cart:", error);
        }
      }
    });

    // Set the updated array as the new state
    // setIscheck(updatedIscheck);
  };

  const calculateTotal = () => {
    if (mappedData.length > 0) {
      return mappedData.reduce(
        (total, item) => total + item.prd_qty * item.price,
        0
      );
    } else {
      return 0; // or any default value you prefer when there are no items
    }
  };

  const handleProceedToCheckout = () => {
    // Check if the user is logged in
    if (!storedUserId) {
      // If not logged in, show a SweetAlert message
      Swal.fire({
        icon: "info",
        title: "Please Log In",
        text: "Kindly log in to proceed to checkout.",
        confirmButtonColor: "#96887d",
        confirmButtonBorder: "#96887d",
      });
    } else {
      // If logged in, navigate to the next page
      navigate(`/Accordion`);
    }
  };

  const getCurrencySymbol = (currency) => {
    switch (currency) {
      case "1":
        return "$";
      case "2":
        return "£";
      case "3":
        return "€";
      default:
        return "€"; // Default to '$' if the region is not matched
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="container mx-auto mt-8 flex flex-col sm:flex-row">
      <div className="w-full sm:w-3/4 pr-4 sm:pr-8">
        <h1 className="text-2xl font-bold mb-4">Product Checkout</h1>
        {mappedData.length > 0 ? (
          mappedData.map((item) => (
            <div
              key={item.prd_id}
              className="flex items-center border-b border-gray-300 py-4 mb-4 sm:flex-row sm:items-center"
            >
              <img
                src={item.src}
                alt={item.text}
                className="mb-4 w-full sm:w-20 sm:h-20 sm:mr-4"
              />
              <div className="flex-grow">
                <p className="text-lg font-semibold">{item.text}</p>
                <p className="text-gray-600">
                  Price: {getCurrencySymbol(item.currency)}
                  {item.price.toFixed(2)}
                </p>
                <div className="flex items-center mt-2">
                  <label className="mr-2">Quantity:</label>

                  <button
                    onClick={() => getDecreaseqty(item.prd_id)}
                    className="bg-[#65451F] text-white hover:text-white hover:bg-[#C8AE7D] h-full w-8 rounded-l cursor-pointer outline-none"
                  >
                    <span class="m-auto text-2xl font-thin">−</span>
                  </button>

                  <input
                    type="text"
                    min="0"
                    value={item.prd_qty}
                    readOnly
                    className="border border-gray-300 px-6 py-[3.5px] w-16"
                  />

                  <button
                    onClick={() => getIncreaseqty(item.prd_id)}
                    className="bg-[#65451F] text-white hover:text-white hover:bg-[#C8AE7D] h-full w-8 rounded-r cursor-pointer outline-none"
                  >
                    <span class="m-auto text-2xl font-thin">+</span>
                  </button>

                  <div className="flex items-center ml-6">
                    <button
                      key={`delete-${item.prd_id}`}
                      onClick={() => deleteProduct(item.prd_id)}
                      className=" px-2 py-1"
                    >
                      <MdDeleteForever color="#ff0000" fontSize="2em" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <>
            <div className="mx-4 md:mr-[24%] md:hidden">
              <div className="flex justify-center">
                <div className="rounded-lg p-4 bg-white shadow-md">
                  <BsCartX fontSize="3rem" color="#96887d" />
                </div>
              </div>
              <div className="flex justify-center text-[22px] md:mr-[-6%]">
                <h4>Your Cart Is Empty</h4>
              </div>
            </div>

            <div className="mx-4 md:mr-[24%] hidden md:block">
              <div className="flex justify-end">
                <div className="rounded-lg p-4 bg-white shadow-md">
                  <BsCartX fontSize="5rem" color="#96887d" />
                </div>
              </div>
              <div className="flex justify-end text-[25px] md:mr-[-6%]">
                <h4>Your Cart Is Empty</h4>
              </div>
            </div>

            <div className="mt-4 md:hidden">
              <div className="text-[14px] text-center">
                If you are looking for your previous products kindly visit
                <a className="ml-1" href="#" onClick={() => navigate("/order")}>
                  Orders or Log In to view your products
                </a>
              </div>
            </div>

            <div className="hidden md:block">
              <div className="text-[18px] text-center ml-[38%]">
                <div>
                  If you are looking for your previous products kindly visit
                  <a
                    className="ml-1"
                    href="#"
                    onClick={() => navigate("/order")}
                  >
                    Orders or Log In to view your products
                  </a>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {mappedData.length > 0 ? (
        <div className="w-full sm:w-1/4 mt-4 sm:mt-14 bg-white p-4 shadow-md">
          <h2 className="text-xl font-bold mb-4">Selected Items</h2>
          <ul className="list-decimal pl-4 mb-4">
            {mappedData.map((item) => (
              <li key={item.prd_id}>
                {item.text}: {item.prd_qty} x {getCurrencySymbol(item.currency)}
                {parseFloat(item.price).toFixed(2)} ={" "}
                {getCurrencySymbol(item.currency)}
                {(item.prd_qty * item.price).toFixed(2)}
              </li>
            ))}
          </ul>

          <div className="border-t border-gray-300 pt-4">
            <p className="text-lg font-semibold">
              Total Amount: {getCurrencySymbol(mappedData[0]?.currency)}
              {calculateTotal().toFixed(2)}
            </p>

            <button
              // onClick={openModal}
              onClick={handleProceedToCheckout}
              className="bg-[#96887d] text-white px-4 py-2 mt-4 w-full"
            >
              Proceed to Checkout
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ProductCheckoutPage;
