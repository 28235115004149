import React, { useState, useEffect } from "react";
import './AccountSettings.css'
import Avatar from "../../images/Login/avatar.png";
import { FiEdit } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import { FaUserEdit } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { GiCancel } from "react-icons/gi";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";

const AccountSettings = () => {

  const MySwal = withReactContent(Swal);
  const [isLoading, setIsLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [editMode1, setEditMode1] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  
  const location = useLocation();
  const userEmail =
    location.state?.userEmail || localStorage.getItem("userEmail");

  const [user, setUser] = useState({
    avatar: Avatar,
  });

  const handleEditClick = () => {
    setEditMode((prevEditMode) => !prevEditMode); // Toggle the edit mode
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // Password validation
    if (name === "user_pass") {
      setPasswordVisible(false);
      const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
      if (!passwordRegex.test(value) && value !== "") {
        setFormSubmitted(false);
      }
    }
  
    // Phone validation
    if (name === "user_phone") {
      const phoneRegex = /^\+?[0-9]{1,15}$/;
      if (!phoneRegex.test(value) && value !== "") {
        setPhoneError(true);
      } else {
        setPhoneError(false);
      }
    }
  
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

 
  const handleRemoveClick = async () => {

    try {
      const removeResponse = await axios.get(
        `https://clientgiftgeenie.design247.in/giftgeenieapi/removeProfileImage.php?user_email=${userEmail}` 
      );

      console.log(removeResponse);

      if (removeResponse.status === 200) {
        setUser({
          avatar: Avatar, // Set the avatar back to the default value
        });
        setEditMode(false); 
      } else {
        console.error("Error removing profile image:", removeResponse.data);
      }
    }  catch (error) {
      // Handle errors
      console.error("Error removing profile image:", error);
      if (error.response && error.response.data) {
        // Log specific parts of the error response
        console.error("Error removing profile image - Status:", error.response.status);
        console.error("Error removing profile image - Message:", error.response.data.message);
      } else {
        console.error("Error removing profile image:", error);
      }
    }
  };

  const handleImageUpload = async (e) => {
    const selectedFile = e.target.files[0];

    const formData = new FormData();
    formData.append("user_img", selectedFile);
    formData.append("user_email", userEmail); // Add this line


    try {
      const response = await axios.post(
        "https://clientgiftgeenie.design247.in/giftgeenieapi/updateProfileImage.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        const updatedUser = response.data;
        console.log("Updated User:", updatedUser);
        setUser((prevUser) => ({
          ...prevUser,
          avatar: `https://clientgiftgeenie.design247.in/giftgeenieapi/${updatedUser.avatar}`,
        }));
        localStorage.setItem("userAvatar", updatedUser.avatar);
        setEditMode(false);
      } else {
        console.error("Error uploading image:", response.data.message);
      }
    } catch (error) {
      // Handle errors
      console.error("Error uploading image:", error);
    }
  };

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    user_email: "",
    user_pass: "",
    user_phone: "",
    user_gender: 0,
    user_img: "",
  });


  useEffect(() => {
    checkProfile();
  }, [userEmail]);

  const checkProfile = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(
        `https://clientgiftgeenie.design247.in/giftgeenieapi/getProfiledata.php?user_email=${userEmail}`
      );

      if (response.status === 200) {
        const responseData = response.data.res[0];
        const imageUrl = responseData.user_img;

        if (imageUrl) {
          setUser((prevUser) => ({
            ...prevUser,
            avatar: `https://clientgiftgeenie.design247.in/giftgeenieapi/${imageUrl}`,
          }));
        } else {
          console.error("user_img is undefined in responseData");
        }
        setFormData(responseData);
      } else {
        console.error("Failed to check authentication");
      }
    } catch (error) {
      console.error("Error checking authentication:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;

  //   // Password validation
  //   if (name === "user_pass") {
  //     setPasswordVisible(false); // Hide password when editing
  //     const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
  //     if (!passwordRegex.test(value) && value !== "") {
  //       // Password should be at least 8 characters long and contain at least one letter and one number
  //       setFormSubmitted(false);
  //     }
  //   }

  //   // Phone validation
  //   if (name === "user_phone") {
  //     const phoneRegex = /^\d+$/;
  //     if (!phoneRegex.test(value) && value !== "") {
  //       // Phone should only contain numbers
  //       setPhoneError(true);
  //     } else {
  //       setPhoneError(false);
  //     }
  //   }

  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     [name]: value,
  //   }));
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    axios
      .post("https://clientgiftgeenie.design247.in/giftgeenieapi/updateProfileDeatils.php", formData)
      .then((response) => {
        if (response.status === 200) {
          MySwal.fire({
            text: "Data Updated Successfully",
            icon: "success",
            confirmButtonColor: "#96887d",
            confirmButtonBorder: "#96887d",
          });
          checkProfile();
        }
        setEditMode1(false);
      })
      .catch((error) => {
        console.error("Error submitting form data:", error);
      });
  };
  
  const handleEditModeToggle = () => {
    setEditMode1(!editMode1); // Toggle the edit mode
  };

  return (
    <div className="p-4">

      <div className="flex justify-center">
      <img
            src={user.avatar}
            alt="Profile"
            className="w-12 h-12 rounded-full mt-2 ml-4"
          />
          <button
            className="top-0 mt-[45px] text-gray-600 cursor-pointer"
            onClick={handleEditClick}
          >
            <FiEdit />
          </button>
          {editMode && (
            <div className="mt-4">
              <input
                type="file"
                name="user_img"
                accept="image/*"
                onChange={handleImageUpload}
                className="flex justify-center ml-10"
              />

              <button
                className="ml-10 mt-4 bg-red-500 text-white p-2 rounded-md hover:bg-red-600"
                onClick={handleRemoveClick}
              >
                Remove
              </button>
            </div>
          )}
        </div>      

     <form className="grid grid-cols-1 gap-4">
            <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={handleEditModeToggle}
                  className="text-sm font-semibold rounded-md text-white bg-[#96887d] px-3 py-2 border border-1 border-black-600 ml-2 mr-2"
                >
                  {editMode1 ? "Cancel" : <FaUserEdit />}
                </button>
                {editMode1 && (
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="rounded-md bg-[#C8AE7D] px-3 py-2 text-sm font-semibold text-white shadow-sm"
                  >
                    Update
                  </button>
                )}
              </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-2 gap-4">
          {/* Add your form fields here */}
          <div className="relative mb-4 md:mb-0">
            <input
              type="text"
              id="floating_outlined"
              name="firstname"
              value={formData.first_name}
              disabled={!editMode1}
              onChange={(e) =>
                setFormData((prevData) => ({
                  ...prevData,
                  first_name: e.target.value,
                }))
              }
              class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
            />
            <label
              for="floating_outlined"
              class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >
              First Name
            </label>
          </div>
         

          <div class="relative">
            <input
              type="text"
              id="floating_outlined"
              name="lastname"
              value={formData.last_name}
              disabled={!editMode1}
              onChange={(e) =>
                setFormData((prevData) => ({
                  ...prevData,
                  last_name: e.target.value,
                }))
              }
              class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
            />
            <label
              for="floating_outlined"
              class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >
              Last Name
            </label>
          </div>

          <div className="relative mb-4 md:mb-0">
            <input
              type="text"
              id="floating_outlined"
              name="email"
              value={formData.user_email}
              disabled
              onChange={(e) =>
                setFormData((prevData) => ({
                  ...prevData,
                  user_email: e.target.value,
                }))
              }
              class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
            />
            <label
              for="floating_outlined"
              class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >
              Email
            </label>
          </div>


          <div className="relative mb-4 md:mb-0">
            <input
             type={passwordVisible ? "text" : "password"}
              id="floating_outlined"
              name="user_pass"
              value={formData.user_pass}
              disabled={!editMode1}
              onChange={(e) =>
                setFormData((prevData) => ({
                  ...prevData,
                  user_pass: e.target.value,
                }))
              }
              class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
            />
             {editMode1 && (
                    <span
                      onClick={() => setPasswordVisible(!passwordVisible)}
                      className="absolute top-1/4 right-10 transform -translate-y-[-4px] cursor-pointer"
                    >
                      {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  )}
            <label
              for="floating_outlined"
              class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >
              Password
            </label>
          </div>

          <div class="relative">
            <input
              type="text"
              id="floating_outlined"
              name="user_phone"
              disabled={!editMode1}
              maxLength={10}
              value={formData.user_phone}
              onChange={handleChange}
           
              class={`block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer
              ${
                phoneError ? "border-red-500" : ""
              }`}
            />
            {phoneError && (
                    <p className="text-red-500 text-sm mt-1">
                      Please enter a valid phone number.
                    </p>
                  )}
            <label
              for="floating_outlined"
              class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >
              Phone
            </label>
          </div>

          <div className="relative">
            <label class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
              Gender:
            </label>
            <select
              name="user_gender"
              value={formData.user_gender}
              disabled={!editMode1}
              onChange={(e) =>
                setFormData((prevData) => ({
                  ...prevData,
                  user_gender: e.target.value,
                }))
              }
              class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
            >
             <option value={0} disabled>
                      Select gender
                    </option>
                    <option value={1}>Male</option>
                    <option value={2}>Female</option>
            </select>
          </div>

         
          </div>
        </form>

    
    </div>
  )
}

export default AccountSettings