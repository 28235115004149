import React, { useState } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as Components from "./Components";
import gift_logo from "../../images/Navbar/Gift Genie Logo 2.svg";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const MySwal = withReactContent(Swal);
  const [signIn, toggle] = React.useState(true);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const isEmailValid = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isEmailValid(email)) {
      MySwal.fire({
        icon: "error",
        title: "Invalid Email",
        text: "Please enter a valid email address.",
        confirmButtonColor: "#96887d",
        confirmButtonBorder: "#96887d",
      });
      return;
    }
    // Add logic to handle sending the email or any other necessary action
    console.log("Email submitted:", email);

    try {
      const processingSwal = Swal.fire({
        icon: "info",
        title: "Processing...",
        html: "Please wait while we process your request.",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        showConfirmButton: false, // Hide the confirmation button while processing
      });

      const forgotResponse = await axios.get(
        `https://clientgiftgeenie.design247.in/giftgeenieapi/getforgotpass.php?user_email=${email}`
      );

      if (forgotResponse.status === 200) {
        MySwal.fire({
          icon: "success",
          title: "Mail Sent Successfully",
          text: "Please check your email for further instructions.",
          confirmButtonColor: "#96887d",
          confirmButtonBorder: "#96887d",
        }).then((result) => {
          if (result.isConfirmed) {
            // Redirect to the login page after successful mail sending
            window.location.href = "/login"; // Replace "/login" with the actual URL of your login page
          }
        });
      } else if (forgotResponse.status === 404) {
        MySwal.fire({
          icon: "error",
          title: "User not found",
          text: "Please Create a new account",
          confirmButtonColor: "#96887d",
          confirmButtonBorder: "#96887d",
        });
      }
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "User not found",
        text: "Please Create a new account",
        confirmButtonColor: "#96887d",
        confirmButtonBorder: "#96887d",
      });
      console.error("Error adding user:", error);
    }
  };

  return (
    <>
      <Components.Section className="bg-cover min-h-screen flex justify-center items-center">
        <div className="flex flex-col justify-center items-center mt-20 h-100vh mb-[50px]">
          <Components.Container className="bg-white rounded-lg shadow-md w-full max-w-md p-6 h-[500px]">
            <Components.SignInContainer signinIn={signIn}>
              <Components.Form onSubmit={handleSubmit}>
                <Components.Title>ForgotPassword</Components.Title>
                <Components.Input
                  type="email"
                  placeholder="Email"
                  name="userEmail"
                  id="email"
                  value={email}
                  onChange={handleEmailChange}
                  required
                />

                <Components.Button type="submit">Send Mail</Components.Button>
              </Components.Form>
            </Components.SignInContainer>

            <Components.OverlayContainer signinIn={signIn}>
              <Components.Overlay signinIn={signIn}>
                <Components.RightOverlayPanel signinIn={signIn}>
                  <Components.Title>
                    <div className="flex text-center">
                      <img src={gift_logo} alt="" className="w-16 mx-auto" />
                    </div>
                  </Components.Title>
                  <Components.Title>Welcome!</Components.Title>
                  <Components.Paragraph>
                    To get your password, please enter your email address!
                  </Components.Paragraph>
                </Components.RightOverlayPanel>
              </Components.Overlay>
            </Components.OverlayContainer>
          </Components.Container>
        </div>
      </Components.Section>
    </>
  );
};

export default ForgotPassword;
