import React from 'react'
import Navbar from "./components/Navbar";
import { CarouselCustomNavigation } from "./components/carousel";
import SectionTitle from "./components/populartitle";
import SectionTitle1 from "./components/populartitle1";
import SectionTitle2 from "./components/populartitle2";
import AboutUs from "./components/aboutus";
import ClientSection from "./components/Client";
import { CarouselCustomNavigation1 } from "./components/newsletter1";
import { FooterWithSocialLinks } from "./components/footer";
import SocialSidebar from "./components/sidebar";
import ScrollToTopButton from "./components/topbutton";
import CategorySection3 from "./components/categories3";
import FeaturedSection1 from "./components/featured";
import CardSection3 from "./components/card3";
import aboutus1 from '../src/components/test/aboutus1';
import CardSection from '../src/components/test/card';
import CardSection1 from '../src/components/test/card1';
import CardSection2 from '../src/components/test/card2';
import CategorySection from '../src/components/test/categories';
import CategorySection1 from '../src/components/test/categories1';
import CategorySection2 from '../src/components/test/categories2';
import ImageRow from '../src/components/test/imagerow';
import ImageSection from '../src/components/test/popular';
import ImageSection1 from '../src/components/test/popular1';
import ImageSection2 from '../src/components/test/popular2';
import  FeaturedSection2 from '../src/components/featured2';
import Footer1 from './components/footer1';
import FloatingIcon from './components/whatsapp';
// import GetCurrentAddress from './components/location';





const Home = () => {

  return (
    <div className="container mx-auto">
       <Navbar/>
     
      <div className='container mx-auto overflow-x-hidden'>
        {/* <CardSection/> */}
        {/* <CardSection1/> */}
        {/* <CardSection2/> */}
        {/* <CategorySection/> */}
        {/* <CategorySection1/> */} 
        {/* <CategorySection2/> */}
        {/* <ImageRow/> */}
        {/* <ImageSection/> */}
        {/* <ImageSection1/> */}
        {/* <ImageSection2/> */}
      <CardSection3 />
      <CarouselCustomNavigation />
      <SectionTitle />

      {/* <FeaturedSection1 /> */}
      <FeaturedSection2 />
      <AboutUs />
      <SectionTitle1 />
      <CategorySection3 />
      <CarouselCustomNavigation1 />
      <SectionTitle2 />
      <ClientSection />
      {/* <FooterWithSocialLinks /> */}
      <FloatingIcon/>
      <ScrollToTopButton />
      <SocialSidebar />
      <Footer1/>
      {/* <GetCurrentAddress/>
       */}
    
      </div>
    </div>
  )
}

export default Home
