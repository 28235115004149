// Footer.js
import React, { useState } from "react";
import "./style.css";
import gift_logo from "../images/Navbar/Gift Genie Logo 2.svg";
import { MdOutlinePhoneIphone } from "react-icons/md";
import { Typography } from "@material-tailwind/react";
import { FaEnvelope } from "react-icons/fa";
import { BsTelegram } from "react-icons/bs";
import { IoLocationSharp } from "react-icons/io5";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function Footer1() {
  const MySwal = withReactContent(Swal);
  const [isLoading, setIsLoading] = useState(true);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    user_email: "",
    Message: "",
  });

  const resetForm = () => {
    setFormData({
      first_name: "",
      last_name: "",
      user_email: "",
      Message: "",
    });
  };

  const userEnquiry = async () => {
    setIsLoading(true);

    // Check if any of the required fields are empty
    if (
      !formData.first_name ||
      !formData.last_name ||
      !formData.user_email ||
      !formData.Message
    ) {
      setIsLoading(false);

      // Show SweetAlert for empty fields
      MySwal.fire({
        icon: "error",
        text: "FirstName & Email is required.",
        confirmButtonColor: "#96887d",
        confirmButtonBorder: "#96887d",
      });

      return; // Do not proceed with the enquiry if any field is empty
    }

    if (!isValidEmail(formData.user_email)) {
      setIsLoading(false);

      // Show SweetAlert for invalid email
      MySwal.fire({
        icon: "error",
        title: "Invalid Email",
        text: "Please enter a valid email address.",
        confirmButtonColor: "#96887d",
        confirmButtonBorder: "#96887d",
      });

      return; // Do not proceed with the enquiry if the email is invalid
    }

    try {
      const userResponse = await axios.post(
        "https://clientgiftgeenie.design247.in/giftgeenieapi/postEnquiryData.php",
        formData
      );

      if (userResponse.status === 200) {
        resetForm();

        // Show SweetAlert
        MySwal.fire({
          icon: "success",
          title: "Enquiry Raised Sucessfully!",
          text: "Our Team You Contact you Soon",
          confirmButtonColor: "#96887d",
          confirmButtonBorder: "#96887d",
        }).then(() => {
          SendEnquireMail(formData.user_email);
        });
      } else {
        console.error("Failed to add the Data");
      }
    } catch (error) {
      console.error("Failed to add the Data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const isValidEmail = (email) => {
    // Use a regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const SendEnquireMail = async (userEmail) => {
    setIsLoading(true);

    try {
      const response = await axios.get(
        `https://clientgiftgeenie.design247.in/giftgeenieapi/getEnquireEmail.php?user_email=${userEmail}`
      );

      if (response.status === 200) {
      } else {
        console.error("Failed to Send Mail");
      }
    } catch (error) {
      console.error("Failed to Send Mail:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const LINKS = [
    {
      items: [
        {
          text: "+1 (234) 567-890",
          icon: <MdOutlinePhoneIphone className="text-2xl text-[#96887d]" />,
        },
        {
          text: "sarah@giftgeenie.com",
          icon: <FaEnvelope className="text-2xl text-[#96887d]" />,
        },
        {
          text: "Virtual Chat M-F 9AM-6PM",
          icon: <BsTelegram className="text-2xl text-[#96887d]" />,
        },
      ],
    },
    {
      // title: "Address",
      items: [
        {
          text: "9665 Bonsai Ln, Riverside, CA, 92508, USA",
          icon: <IoLocationSharp className="text-2xl text-[#96887d]" />,
        },
      ],
    },
  ];

  const currentYear = new Date().getFullYear();

  const [iconHovered, setIconHovered] = useState(null);
  const [iconHovered1, setIconHovered1] = useState(null);
  const [iconHovered2, setIconHovered2] = useState(null);

  const handleIconHover = (icon) => {
    setIconHovered(icon);
  };

  const handleIconLeave = () => {
    setIconHovered(null);
  };

  const handleIconHover1 = (icon) => {
    setIconHovered1(icon);
  };

  const handleIconLeave1 = () => {
    setIconHovered1(null);
  };

  const handleIconHover2 = (icon) => {
    setIconHovered2(icon);
  };

  const handleIconLeave2 = () => {
    setIconHovered2(null);
  };

  return (
    <footer className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-10 mt-4 border-t-[2px] border-[#96887d]">
      <div className="mx-auto w-full max-w-7xl px-8">
        <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
          {/* Logo */}
          <div className="mb-4 md:mb-0">
            <img
              src={gift_logo}
              alt="Logo"
              className="w-[70px] md:w-[140px] h-auto"
            />
          </div>

          {/* Social Icons */}
          <div>
            {LINKS.map(({ title, items }) => (
              <ul key={title} className="mr-4">
                <Typography className="text-[#65451F] text-[8px] md:text-[14px] opacity-70">
                  {title}
                </Typography>
                {items.map((link) => (
                  <li
                    key={link.text}
                    className="flex items-center text-[8px] md:text-[14px]"
                  >
                    <Typography
                      as="a"
                      href="#"
                      className="py-1.5 text-[#4f4a4a] transition-colors flex"
                    >
                      {link.icon && <span className="mr-2">{link.icon}</span>}
                      {link.text && <span className="">{link.text}</span>}
                    </Typography>
                  </li>
                ))}
              </ul>
            ))}
          </div>

          <div className="flex justify-start">
            <ul className="text-[#4f4a4a] mb-2 mt-4 md:mt-0">
              <div className="text-[#65451F] text-[14px] opacity-70">
                Products
              </div>

              <li className="flex items-center text-[14px] mt-2 mr-[290px] md:mr-[0px]">
                HOME
              </li>
              <li className="flex items-center text-[14px] mt-2">FEATURES</li>
              <li className="flex items-center text-[14px] mt-2">CATEGORIES</li>
              <li className="flex items-center text-[14px] mt-2">ABOUT US</li>
            </ul>
          </div>

          {/* Contact Us Form */}
          <div className="mb-4 md:mb-0 mt-4">
            <h2 className="mb-3 text-[#65451F] opacity-70">Contact Us</h2>
            <form>
              {/* Add your form fields here */}
              <div className="mb-4">
                <div className="mb-4 flex space-x-4">
                  <div className="relative w-1/2">
                    <input
                      type="text"
                      id="floating_outlined"
                      name="firstname"
                      value={formData.first_name}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          first_name: e.target.value,
                        }))
                      }
                      class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-[#96887d] focus:outline-none focus:ring-0 focus:border-[#96887d] peer"
                      placeholder=" "
                    />
                    <label
                      for="floating_outlined"
                      class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-[#65451F] peer-focus:dark:text-[#65451F] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      First Name
                    </label>
                  </div>
                  <div className="relative w-1/2">
                    <input
                      type="text"
                      id="floating_outlined"
                      name="lastname"
                      value={formData.last_name}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          last_name: e.target.value,
                        }))
                      }
                      class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-[#96887d] focus:outline-none focus:ring-0 focus:border-[#96887d] peer"
                      placeholder=" "
                    />
                    <label
                      for="floating_outlined"
                      class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-[#65451F] peer-focus:dark:text-[#65451F] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Last Name
                    </label>
                  </div>
                </div>
                <div className="relative mb-4">
                  <input
                    type="text"
                    id="floating_outlined"
                    name="email"
                    value={formData.user_email}
                    onChange={(e) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        user_email: e.target.value,
                      }))
                    }
                    class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-[#96887d] focus:outline-none focus:ring-0 focus:border-[#96887d] peer"
                    placeholder=" "
                  />
                  <label
                    for="floating_outlined"
                    class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-[#65451F] peer-focus:dark:text-[#65451F] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Email
                  </label>
                </div>
              </div>
              <div className="relative mb-4">
                <textarea
                  type="text"
                  id="floating_outlined"
                  name="Message"
                  value={formData.Message}
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      Message: e.target.value,
                    }))
                  }
                  class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-[#96887d] focus:outline-none focus:ring-0 focus:border-[#96887d] peer"
                  placeholder=" "
                />
                <label
                  for="floating_outlined"
                  class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-[#65451F] peer-focus:dark:text-[#65451F] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >
                  Message
                </label>
              </div>

              {/* Add more form fields as needed */}
            </form>
            <button
              onClick={() => {
                userEnquiry();
              }}
              type="submit"
              className="bg-[#4f4a4a] text-white px-4 py-2 rounded-md hover:bg-[#65451F] transition-colors"
            >
              Submit
            </button>
          </div>
        </div>
        <div className="flex gap-4 text-center justify-center text-black sm:justify-center mt-4">
          <Typography
            as="a"
            href="#"
            className={`opacity-80 transition-opacity hover:opacity-100 ${
              iconHovered === "facebook" ? "text-blue-500" : ""
            }`}
            onMouseEnter={() => handleIconHover("facebook")}
            onMouseLeave={handleIconLeave}
          >
            <svg
              className="h-5 w-5"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                clip-rule="evenodd"
              />
            </svg>
          </Typography>
          <Typography
            as="a"
            href="#"
            className={`opacity-80 transition-opacity hover:opacity-100 ${
              iconHovered1 === "instagram" ? "text-[#d62976]" : ""
            }`}
            onMouseEnter={() => handleIconHover1("instagram")}
            onMouseLeave={handleIconLeave1}
          >
            <svg
              className="h-5 w-5"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                clip-rule="evenodd"
              />
            </svg>
          </Typography>
          <Typography
            as="a"
            href="#"
            className={`opacity-80 transition-opacity hover:opacity-100 ${
              iconHovered2 === "twitter" ? "text-blue-400" : ""
            }`}
            onMouseEnter={() => handleIconHover2("twitter")}
            onMouseLeave={handleIconLeave2}
          >
            <svg
              className="h-5 w-5"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
            </svg>
          </Typography>
        </div>
        <div className="mt-6 flex w-full flex-col items-center justify-center border-t border-blue-gray-50 py-4 md:flex-row md:justify-center">
          <Typography
            variant="small"
            className="mb-4 text-center font-normal text-[#65451F] md:mb-0"
          >
            &copy; {currentYear}{" "}
            <a href="https://material-tailwind.com/">Gift Geenie</a>. All Rights
            Reserved.
          </Typography>
        </div>
      </div>
    </footer>
  );
}

export default Footer1;
