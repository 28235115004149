import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Breadcrumbs from "../breadcrumbs1";
import { FooterWithSocialLinks } from "../footer";
import { FaPlus, FaStar } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import axios from "axios";
import { useRegion } from "../Region";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Slider from "react-slider";
import "../style.css";

function ProductFilter() {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { sourceId } = useParams();
  const [data, setProducts] = useState([]);
  const [General, setGeneral] = useState([]);
  const { selectedRegion, setRegion } = useRegion();
  const [selectedGeneralCate, setGeneralCate] = useState([]);
  const [selectedSubcate, setSubcate] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [productsToDisplay, setProductsToDisplay] = useState(12);

  const [minRange, setMinRange] = useState(0);
  const [maxRange, setMaxRange] = useState(0);

  let MIN = minRange;
  let MAX = maxRange;
  // const [priceRange, setPriceRange] = useState([]);

  const [values, setvalues] = useState([MIN, MAX]);

  const [hoveredCard, setHoveredCard] = useState(null);
  const [openCategory, setOpenCategory] = useState(null);

  const handleClearFilters = () => {
    setGeneralCate([]);
    setSubcate([]);
    setMinRange(0);
    setMaxRange(0);
    setSelectedFilters([]);
    setOpenCategory(null);

    setvalues([minRange, maxRange]);

    setProductsToDisplay(productsToDisplay);
  };

  const [postData, setPostData] = useState({
    GeneralId: selectedGeneralCate,
    SubId: selectedSubcate,
    RegionId: selectedRegion,
    Minvalue: MIN,
    Maxvalue: MAX,
  });

  // useEffect(() => {
  //   getMaxData();
  // }, [selectedRegion]);

  useEffect(() => {
    // Update the postData object with the new values
    const updatedPostData = {
      GeneralId: selectedGeneralCate,
      SubId: selectedSubcate,
      RegionId: selectedRegion,
      Minvalue: values[0],
      Maxvalue: values[1],
    };

    // Set the updated postData to the state
    setPostData(updatedPostData);

    // Fetch product data with the new price filter
    getAllProductData(updatedPostData);

    // Extract the names of selected general categories
    const selectedGeneralNames = General.filter((category) =>
      selectedGeneralCate.includes(category.General_id)
    ).map((category) => category.General_name);

    // Extract the names of selected subcategories
    const selectedSubNames = General.reduce((subNames, category) => {
      if (Array.isArray(category.SubCategory)) {
        const selectedSubs = category.SubCategory.filter((subcate) =>
          selectedSubcate.includes(subcate.sub_id)
        ).map((subcate) => subcate.sub_category_name);

        return [...subNames, ...selectedSubs];
      }
      return subNames;
    }, []);

    let allSelectedFilters = [...selectedGeneralNames, ...selectedSubNames];

    if (values[0] !== minRange || values[1] !== maxRange) {
      const priceRangeFilter = `$${values[0]} - $${values[1]}`;
      allSelectedFilters.push(priceRangeFilter);
    }

    setSelectedFilters(allSelectedFilters);
  }, [
    selectedRegion,
    selectedGeneralCate,
    selectedSubcate,
    values,
    General,
    minRange,
    maxRange,
  ]);

  const getAllProductData = async (postData) => {
    setIsLoading(true);
    try {
      const ViewAllResponse = await axios.post(
        "https://clientgiftgeenie.design247.in/giftgeenieapi/getAllProducts.php",
        postData
      );

      console.log(ViewAllResponse);
      if (ViewAllResponse.status === 200) {
        const ViewAllResponseData = ViewAllResponse.data.res;

        setProducts(ViewAllResponseData);
      } else {
        console.log("No Data Fetched");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Handle the 404 status if needed
        setProducts([]);
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getCurrencySymbol = (region_id) => {
    switch (region_id) {
      case "1":
        return "$";
      case "2":
        return "£";
      case "3":
        return "€";
      default:
        return "€"; // Default to '$' if the region is not matched
    }
  };

  useEffect(() => {
    getGeneralData();
    getMinData();
    getMaxData();
  }, [selectedRegion, minRange, maxRange]);

  const getMinData = async () => {
    setIsLoading(true);
    try {
      const MinResponse = await axios.get(
        `https://clientgiftgeenie.design247.in/giftgeenieapi/getMinPrice.php?region_id=${selectedRegion}`
      );

      if (MinResponse.status === 200) {
        const MinResponseData = MinResponse.data.res;

        setMinRange(MinResponseData);
      } else {
        console.log("No Data Fetched");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setMinRange();
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getMaxData = async () => {
    setIsLoading(true);
    try {
      const MaxResponse = await axios.get(
        `https://clientgiftgeenie.design247.in/giftgeenieapi/getMaxPrice.php?region_id=${selectedRegion}`
      );

      if (MaxResponse.status === 200) {
        const MaxResponseData = MaxResponse.data.res;

        console.log("MaxResponseData:", MaxResponseData);
        const maxPrice = MaxResponseData;

        setMaxRange(MaxResponseData); // Update 'maxRange' state with new data
        setvalues([minRange, maxPrice]); // Update 'values' state with new max value
      } else {
        console.log("No Data Fetched");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setvalues([minRange, 0]);
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const productData =
    data &&
    data.map((item) => ({
      src: `https://overseasgift.design247.in/${item.img_url}`,
      text: item.prod_name,
      price: item.prod_cost,
      prd_id: item.prd_id,
    }));

  const getGeneralData = async () => {
    setIsLoading(true);
    try {
      const GeneralResponse = await axios.get(
        `https://clientgiftgeenie.design247.in/giftgeenieapi/getGeneral.php?region_id=${selectedRegion}`
      );

      if (GeneralResponse.status === 200) {
        const GeneralResponseData = GeneralResponse.data.res;

        const GeneralValues = GeneralResponseData.map((item) => ({
          General_name: item.g_category_name,
          General_id: item.g_id,
          General_count: item.subgen_count,
          SubCategory: item.subcate,
        }));

        setGeneral(GeneralValues);
      } else {
        console.log("No Data Fetched");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setGeneral();
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handlegenerealOption = (GeneralId) => {
    const isSelected = selectedGeneralCate.includes(GeneralId);

    if (isSelected) {
      setGeneralCate((prevgId) => prevgId.filter((gId) => gId !== GeneralId));
      setOpenCategory(null);
    } else {
      setGeneralCate((prevgId) => [...prevgId, GeneralId]);
      setOpenCategory(GeneralId);
    }
  };

  // const handleSubOption = (SubId) => {
  //   const isSelected = selectedSubcate.includes(SubId);

  //   if (isSelected) {
  //     setSubcate((prevsId) => prevsId.filter((sId) => sId !== SubId));
  //   } else {
  //     setSubcate((prevsId) => [...prevsId, SubId]);
  //   }
  // };

  const handleSubOption = (SubId, GeneralId) => {
    const isSelected = selectedSubcate.includes(SubId);

    if (isSelected) {
      setSubcate((prevsId) => prevsId.filter((sId) => sId !== SubId));
    } else {
      setSubcate((prevsId) => [...prevsId, SubId]);

      // Check the corresponding general category when a subcategory is checked
      if (!selectedGeneralCate.includes(GeneralId)) {
        setGeneralCate((prevgId) => [...prevgId, GeneralId]);
        setOpenCategory(GeneralId);
      }
    }
  };

  const handleCategoryToggle = (General_id) => {
    setOpenCategory((prevOpenCategory) => {
      setGeneralCate((prevgId) =>
        prevgId.includes(General_id)
          ? prevgId.filter((gId) => gId !== General_id)
          : [...prevgId, General_id]
      );

      // Remove selected subcategories if the general category is unchecked
      if (prevOpenCategory === General_id) {
        setSubcate([]);
      }

      return prevOpenCategory === General_id ? null : General_id;
    });
  };

  // const loadfunction = () => {
  //   const slicedProducts = productData.slice(0, productsToDisplay);
  //   setDisplayedProducts(slicedProducts);
  // };

  useEffect(() => {
    const slicedProducts = productData.slice(0, productsToDisplay);
    setDisplayedProducts(slicedProducts);
  }, [productData, productsToDisplay]);

  const handleLoadMore = () => {
    setProductsToDisplay((prev) => prev + 12);
    // loadfunction();
  };

  return (
    <div>
      <Navbar />
      <Breadcrumbs
        level1="Product Fliter"
        level1Path={`/productfilterpage/${sourceId}`}
      />

      <div className="flex flex-col md:flex-row">
        {/* Left side - Filter options */}
        <div className="w-full md:w-1/4 p-4 h-auto md:h-screen overflow-y-auto md:overflow-hidden">
          <h2 className="text-[22px] text-[#65451F] font-semibold mb-4 border-b border-black w-[140px]">
            Filter Options
          </h2>

          <div className="text-sm mb-4">
            <h3 className="text-lg text-[#765827] font-semibold mb-2">
              Product category
            </h3>
            {General.map((category, index) => (
              <div key={index} className="mb-2">
                <div className="flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={selectedGeneralCate.includes(category.General_id)}
                    onChange={() => handlegenerealOption(category.General_id)}
                    className="mr-2"
                  />
                  <a
                    htmlFor={category.General_id}
                    className="text-md text-[#765827] font-semibold"
                    onClick={() => handlegenerealOption(category.General_id)}
                  >
                    {category.General_name}({category.General_count})
                  </a>
                  <span
                    className="ml-auto"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCategoryToggle(category.General_id);
                    }}
                  >
                    {openCategory === category.General_id ? (
                      <IoIosArrowDown />
                    ) : (
                      <IoIosArrowForward />
                    )}
                  </span>
                </div>

                {openCategory === category.General_id && (
                  <div className="ml-4">
                    {Array.isArray(category.SubCategory) &&
                    category.SubCategory.length > 0 ? (
                      category.SubCategory.map((subcate, index) => (
                        <div
                          key={index}
                          className="flex items-center mb-2 mt-2 cursor-pointer"
                        >
                          <input
                            type="checkbox"
                            checked={selectedSubcate.includes(subcate.sub_id)}
                            onChange={() =>
                              handleSubOption(
                                subcate.sub_id,
                                category.General_id
                              )
                            }
                            className="mr-2"
                          />
                          <a
                            onClick={() =>
                              handleSubOption(
                                subcate.sub_id,
                                category.General_id
                              )
                            }
                          >
                            {subcate.sub_category_name}({subcate.sub_count})
                          </a>
                        </div>
                      ))
                    ) : (
                      <label>No SubCategories Found</label>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* Price Filter */}
          <div>
            <h3 className="text-lg text-[#765827] font-semibold mb-2">
              Price Range
            </h3>

            <Slider
              className={"slider"}
              onChange={(newValues) => {
                setvalues(newValues);
              }}
              onAfterChange={(newValues) => {
                // Update the postData object with the new values
                const updatedPostData = {
                  GeneralId: selectedGeneralCate,
                  SubId: selectedSubcate,
                  RegionId: selectedRegion,
                  Minvalue: newValues[0],
                  Maxvalue: newValues[1],
                };

                // Set the updated postData to the state
                setPostData(updatedPostData);

                // Fetch product data with the new price filter
                getAllProductData(updatedPostData);
              }}
              value={values}
              min={minRange}
              max={maxRange}
            />
            <div className="mt-2">
              {getCurrencySymbol(selectedRegion)}
              {values[0]} - {getCurrencySymbol(selectedRegion)}
              {values[1]}
            </div>
            <small>
              Current Range : {getCurrencySymbol(selectedRegion)}
              {values[1] - values[0]}
            </small>
          </div>
        </div>

        {/* Right side - Product cards */}
        <div className="w-full md:w-3/4 mt-4 md:mt-0">
          {/* Display selected categories at the top */}
          {selectedFilters.length > 0 && (
            <div className="text-lg font-bold ml-4">
              Selected Filters: {selectedFilters.join(", ")}
              <button
                onClick={handleClearFilters}
                className="ml-4 px-2 py-1 bg-[#765827] text-white rounded"
              >
                Clear Filters
              </button>
            </div>
          )}

          <div className="grid grid-cols-1 md:grid-cols-3 gap-2 md:mx-auto ml-[60px]">
            {displayedProducts.map((item, index) => (
              <div
                className="relative group"
                key={index}
                onMouseEnter={() => setHoveredCard(index)}
                onMouseLeave={() => setHoveredCard(null)}
              >
                <div className="p-4">
                  <div
                    className="border rounded-xl shadow-lg overflow-hidden transition duration-300 transform group-hover:scale-105"
                    style={{ height: "380px", width: "270px" }}
                  >
                    <div className="h-[280px] W-[100px]">
                      <img
                        src={item.src}
                        alt={item.text}
                        className="w-full h-full object-cover rounded-t-xl"
                      />
                    </div>
                    <FaPlus
                      className="absolute top-1 right-1 p-2 text-[#AFAFAF] rounded-bl-lg  rounded-tr-lg bg-white shadow-lg text-4xl block cursor-pointer"
                      onClick={() => {
                        navigate(
                          `/productfilterpage/${sourceId}/ProductDetailsPage/${item.prd_id}`
                        );
                      }}
                    />

                    <p className="text-gray-600 p-4 text-[14px]">{item.text}</p>
                    <div className="flex items-center">
                      <p className="text-[14px] text-black p-4 -mt-5">
                        ${item.price}
                      </p>
                    </div>
                    <p className="text-lg text-gray-600 p-4 -mt-5">
                      {/* {product.category} */}
                    </p>
                    {hoveredCard === item.id && (
                      <button className="absolute mx-auto bottom-7 left-0 right-0 bg-[#765827] text-white p-2 text-center rounded-xl mb-2 mr-5">
                        Add to Catalogue
                      </button>
                    )}
                    {/* <p className="text-sm font-semibold">{product.name}</p>
                <p className="text-xs text-gray-500 mb-2">{product.category}</p>
                <p className="text-sm">${product.price}</p> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
          {selectedFilters.length === 0 &&
            productData.length > productsToDisplay && (
              <div className="text-center mt-4">
                <button
                  onClick={handleLoadMore}
                  className="mx-auto py-[10px] px-[20px] font-[16px] rounded-lg bg-[#765827] hover:bg-[#65451F] text-white hover:text-white transition-colors 0.3s"
                >
                  Load More
                </button>
              </div>
            )}
        </div>
      </div>
      <FooterWithSocialLinks />
    </div>
  );
}

export default ProductFilter;
