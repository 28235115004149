import { Fragment, useState, useEffect } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BiUserCircle, BiSearch, BiPlus } from "react-icons/bi";
import { HiOutlineShoppingCart } from "react-icons/hi";
import { AiOutlineRight } from "react-icons/ai";
import "./style.css";
import gift_logo from "../images/Navbar/Gift Genie Logo 2.svg";
import UK from "../images/Navbar/UK-Flag.svg";
import USD from "../images/Navbar/US-Flag.svg";
import EUR from "../images/Navbar/Flag-Europe.svg";
// import IND from "../images/Navbar/IND-Flag.svg";
import Dollar from "../images/Navbar/currency-dollar.svg";
import Pound from "../images/Navbar/currency-pound.svg";
import Euro from "../images/Navbar/currency-euro.svg";
// import Rupees from "../images/Navbar/rupees-logo-new.svg";
import DropdownMenu from "./Dropdown/DropdownMenu";
import ProductHeading from "../components/productdetails/ProductHeading";
import DropDown1 from "./dropdown1";
import { uuid } from "./uuid";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import axios from "axios";
import { useRegion } from "./Region";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../components/AuthContext";
import { useLocation } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Navbar = () => {
  // const [userId] = useState(uuid());
  // console.log(userId);
  const [isLoading, setIsLoading] = useState(true);
  const [userId, setUserId] = useState("");
  const [isCardOpen, setIsCardOpen] = useState(false);
  const [isPopularCategoriesOpen, setIsPopularCategoriesOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedRegionId, setSelectedRegionId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [inputFocused, setInputFocused] = useState(false);
  const [data, setData] = useState([]);
  const [cart, setCart] = useState("");
  const { selectedRegion, setRegion } = useRegion();
  const [myArray, setMyArray] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();
  // const [productCount, setProductCount] = useState(false);
  const userEmail =
    location.state?.userEmail || localStorage.getItem("userEmail");

  const storedUserId = localStorage.getItem("userId");

  const storedRegion = sessionStorage.getItem("selectedRegion");

  const navigate = useNavigate();

  const updateCartCount = (newCount) => {
    setCart([{ Count: newCount }]);
  };

  const handleSignInClick = () => {
    // Store the current path in local storage before navigating to the login page
    localStorage.setItem("redirectUrl", location.pathname);
    navigate("/Login");
  };

  // useEffect(() => {

  //   setUserId(newUserId);

  //   // Store the userId in cookies
  //   Cookies.set("userId", newUserId);
  // }, []); // Run this effect only once on component mount

  const handleCardToggle = () => {
    setIsCardOpen(!isCardOpen);
  };

  const closeCard = () => {
    setIsCardOpen(false);
  };

  const handlePopularCategoriesToggle = () => {
    setIsPopularCategoriesOpen(!isPopularCategoriesOpen);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen); // Toggle the dropdown state
  };

  const handleRegionSelect = (regionId) => {
    setRegion(regionId);
    setIsDropdownOpen(false); // Close the dropdown when a region is selected
  };

  const handleInputFocus = () => {
    setInputFocused(true);
  };

  const handleInputBlur = () => {
    setInputFocused(false);
  };

  useEffect(() => {
    getRegion();
    checkAuthentication();
    const interval = setInterval(() => {
      if (localStorage.getItem("userId") === "") {
        setCart(0);
      } else {
        getCartCount();
      }
    }, 500);
    return () => clearInterval(interval);
  }, [selectedRegion, userEmail, isAuthenticated]);

  const getRegion = async () => {
    setIsLoading(true);
    try {
      const RegionResponse = await axios.get(
        `https://clientgiftgeenie.design247.in/giftgeenieapi/getRegion.php`
      );
      if (RegionResponse.status === 200) {
        const RegionResponseData = RegionResponse.data.res;
        setData(RegionResponseData);
      } else if (RegionResponse.status === 400) {
        // Handle the 400 status if needed
      } else {
        console.log("No Data Fetched");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCartCount = async () => {
    setIsLoading(true);
    try {
      const CountResponse = await axios.get(
        `https://clientgiftgeenie.design247.in/giftgeenieapi/getProductCount.php?user_id=${storedUserId}`
      );

      if (CountResponse.status === 200) {
        const CountResponseData = CountResponse.data.res;
        const CountValues = CountResponseData.map((item) => ({
          Count: item.productCount,
        }));

        setCart(CountValues);
      } else if (CountResponse.status === 400) {
        // Handle the 400 status if needed
      } else {
        console.log("No Data Fetched");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkAuthentication = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(
        `https://clientgiftgeenie.design247.in/giftgeenieapi/getNavLogin.php?user_email=${userEmail}`
      );

      if (response.status === 200) {
        setIsAuthenticated(true);
      } else {
        console.error("Failed to check authentication");
      }
    } catch (error) {
      console.error("Error checking authentication:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkSignOut = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(
        `https://clientgiftgeenie.design247.in/giftgeenieapi/getNavSignOut.php?user_email=${userEmail}`
      );

      if (response.status === 200) {
        setIsAuthenticated(false);
        localStorage.setItem("userId", "");
        window.location.reload();
      } else {
        console.error("Failed to check authentication");
      }
    } catch (error) {
      console.error("Error checking authentication:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCurrencySymbol = (region_id) => {
    // Use the storedRegion value if it exists, otherwise use the selectedRegion
    const currentRegionId = storedRegion || selectedRegion;
    switch (currentRegionId) {
      case "1":
        return Dollar;
      case "2":
        return Pound;
      case "3":
        return Euro;
      default:
        return Euro; // Default to '$' if the region is not matched
    }
  };

  const getImageSource = (currency) => {
    switch (currency) {
      case "US Dollar":
        return USD;
      case "Pound":
        return UK;
      case "Euro":
        return EUR;
      default:
        return USD; // Use a default image if the symbol doesn't match
    }
  };

  return (
    <>
      <Disclosure as="nav" className="sticky top-0 z-50 bg-white border-[1px]">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-10 h-[66px]">
              <div className="relative flex items-center justify-between h-14 md:justify-between top-1">
                {/* Left side collapse button */}
                {/* <div
                  className="cursor-pointer ml-4  md:ml-0"
                  onClick={handleCardToggle}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-gray-600 inline-block"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    {isCardOpen ? (
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    ) : (
                      <image
                        xlinkHref="https://cdn.igp.com/raw/upload/assets/svg-icons/mm-new-logo.svg"
                        width="24"
                        height="24"
                      />
                    )}
                  </svg>
                </div> */}

                {/* Search bar with search icon */}
                <div className="flex items-center md:items-center mx-auto pr-[80px] md:pl-[150px]">
                  {/* <label
                  htmlFor="searchInput"
                  className={`absolute pl-4 text-[14px] ${
                    inputFocused ? "hidden" : ""
                  }`}
                >
                  <p>Search for gifts</p>
                </label>

                <input
                  type="text"
                  id="searchInput"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder=""
                  className="border border-[#65451F] rounded-full p-1 pl-4  w-[430px]"
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                />
                <div className="mr-4 absolute top-[1em] right-[35em] w-[1em] text-xs">
                  <BiSearch color="black" fontSize="1.8em" />
                </div> */}
                  <img
                    src={gift_logo}
                    alt=""
                    className="mx-auto w-24 h-24 pl-10 ml-10 md:pl-10 md:ml-10 cursor-pointer"
                    onClick={() => {
                      navigate(`/`);
                    }}
                  />
                </div>

                <div className="md:flex md:items-center md:top-1 absolute inset-y-0 right-0 flex align-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <div className="relative top-2 md:top-0">
                    <button
                      onClick={handleDropdownToggle}
                      className="text-black cursor-pointer hover:text-rose-600 mt-0.5 w-7 h-7"
                    >
                      <img
                        src={getCurrencySymbol(selectedRegion)}
                        alt=""
                        className="w-full h-full"
                      />
                    </button>
                    {isDropdownOpen && (
                      <div className="absolute top-12 left-0  mt-2 w-30 bg-white p-4 rounded-lg shadow-lg text-black ">
                        {data.map(
                          (currency, index) =>
                            index !== 3 && (
                              <div
                                key={currency.symbol}
                                className="flex items-center hover:bg-gray-100 p-2"
                                onClick={() => handleRegionSelect(currency.id)}
                              >
                                <div className="w-6 h-6 rounded-full overflow-hidden">
                                  <img
                                    src={getImageSource(currency.currency)}
                                    alt={`Image for ${currency.currency}`}
                                    className="w-full h-full object-cover"
                                  />
                                </div>
                                <div className="ml-4">
                                  <p className="text-[12px] font-semibold">
                                    {currency.region_name}
                                  </p>
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    )}
                  </div>
                  <Menu as="div" className="relative ml-2 top-2 md:top-0">
                    <div className="inline-block">
                      <Menu.Button className="relative rounded-full text-sm focus:outline-none p-1 mr-1">
                        <Link to="/ProductCheckoutpage">
                          <HiOutlineShoppingCart
                            color="black"
                            fontSize="1.8em"
                          />
                          <span className="absolute top-0 right-0 -mt-1 -mr-1 bg-red-500 text-white rounded-full px-2">
                            {cart.length > 0 && cart[0].Count}
                          </span>
                        </Link>
                      </Menu.Button>
                    </div>
                    <div className="inline-block">
                      <Menu.Button className="relative mx-0 rounded-full text-sm focus:outline-none p-1">
                        <BiUserCircle color="black" fontSize="1.8em" />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {isAuthenticated ? (
                          <>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                  onClick={() => {
                                    navigate(`/user/:activepage`);
                                  }}
                                >
                                  Profile
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                  onClick={() => {
                                    navigate("/order");
                                  }}
                                >
                                  Orders
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                  onClick={() => {
                                    // Perform sign-out logic
                                    checkSignOut(userEmail);
                                  }}
                                >
                                  Sign out
                                </a>
                              )}
                            </Menu.Item>
                          </>
                        ) : (
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                                onClick={handleSignInClick}
                              >
                                Sign In
                              </a>
                            )}
                          </Menu.Item>
                        )}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>

              {/* {isCardOpen && <DropdownMenu />} */}
            </div>
          </>
        )}
      </Disclosure>
    </>
  );
};

export default Navbar;
