import React, { useState, useEffect } from "react";
import ".././style.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useRegion } from "../Region";

function ViewTitle() {
  const [isLoading, setIsLoading] = useState(true);
  const { sourceId } = useParams();
  const [Title, settitle] = useState([]);
  const { selectedRegion, setRegion } = useRegion();

  useEffect(() => {
    getViewTitle();
  }, [selectedRegion, sourceId]);

  const getViewTitle = async () => {
    setIsLoading(true);
    try {
      const ViewTitleResponse = await axios.get(
        `https://clientgiftgeenie.design247.in/giftgeenieapi/getViewTitle.php?sourceId=${sourceId}&regionId=${selectedRegion}`
      );
      if (ViewTitleResponse.status === 200) {
        const ViewTitleResponseData = ViewTitleResponse.data.res;
        const TitleValues = ViewTitleResponseData.map((item) => ({
          Title_name: item.g_category_name,
          Title_id: item.g_id,
        }));
        settitle(TitleValues);
      } else {
        console.log("No Data Fetched");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Handle the 404 status if needed
        settitle([]);
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
      {Title.map((title) => (
        <div className="md:w-full md:px-4 pb-4">
          <p className="text-[20px] md:text-[28px] font-semibold text-[#65451F]">
            {title.Title_name}
          </p>
          {/* <p className="text-[16px] text-[#836a4b] text-bold">
          Meet Our Clients Here
        </p> */}
        </div>
      ))}
    </div>
  );
}

export default ViewTitle;
