import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Medals from "../images/Header6/Artboard 11.jpg";
import sustainable from "../images/Header6/Artboard 10.jpg";
import bags from "../images/Header6/Artboard 9.jpg";
import food from "../images/Header6/Artboard 8.jpg";
import office from "../images/Header6/Artboard 7.jpg";
import events from "../images/Header6/Artboard 6.jpg";
import gifts from "../images/Header6/Artboard 5.jpg";
import christmas from "../images/Header6/Artboard 4.jpg";
import speakers from "../images/Header6/Artboard 3.jpg";
import Mugs from "../images/Header6/Artboard 2.jpg";
import tech from "../images/Header6/Artboard 1.jpg";
import cloth from "../images/Header6/Artboard 12.jpg";
import sports from "../images/Header6/Artboard 13.jpg";
import toys from "../images/Header6/Artboard 14.jpg";

import { useNavigate } from "react-router-dom";

function CardSection3() {
  const navigate = useNavigate();
  const cards = [
    {
      imageUrl: tech,
      route: "/viewproducts/1",
    },
    {
      imageUrl: Mugs,
      route: "/viewproducts/2",
    },
    {
      imageUrl: bags,
      route: "/viewproducts/3",
    },
    {
      imageUrl: office,
      route: "/viewproducts/4",
    },
    {
      imageUrl: food,
      route: "/viewproducts/5",
    },
    {
      imageUrl: sports,
      route: "/viewproducts/6",
    },
    {
      imageUrl: cloth,
      route: "/viewproducts/7",
    },
    {
      imageUrl: speakers,
      route: "/viewproducts/8",
    },
    {
      imageUrl: Medals,
      route: "/viewproducts/9",
    },
    {
      imageUrl: christmas,
      route: "/viewproducts/11",
    },
    {
      imageUrl: gifts,
      route: "/viewproducts/12",
    },
    {
      imageUrl: toys,
      route: "/viewproducts/13",
    },
    {
      imageUrl: sustainable,
      route: "/viewproducts/14",
    },

    {
      imageUrl: events,
      route: "/viewproducts/15",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  const Card = ({ imageUrl, route }) => {
    const handleCardClick = () => {
      navigate(route);
    };

    return (
      <div
        className="p-4 mx-auto max-w-md sm:max-w-2xl lg:max-w-7xl px-2 sm:px-6 lg:px-5"
        onClick={handleCardClick}
      >
        <div className="bg-white rounded-lg shadow-lg">
          <img
            src={imageUrl}
            alt=""
            className="w-auto h-auto object-cover rounded-lg transition-transform duration-300 transform scale-100 hover:scale-105 cursor-pointer"
          />
        </div>
      </div>
    );
  };

  return (
    <div className="px-5">
      <Slider {...settings}>
        {cards.map((card, index) => (
          <Card key={index} imageUrl={card.imageUrl} route={card.route} />
        ))}
      </Slider>
    </div>
  );
}

export default CardSection3;
